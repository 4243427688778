import React from 'react'
import {
  Header,
  HeaderInner,
  Hamburger,
  Close,
  ButtonGroup,
  StyledLogo,
  FAQ,
  SpeakingBubble,
  MyPages,
  ExternalLinkIcon
} from './styles'
import Menu from '../Menu'
import CustomLink from '../CustomLink'

export default ({ menuItems }) => {
  const toggleBodyClass = () => {
    const body = document.querySelector('body')
    body.classList.toggle('menu-open')
  }

  return (
    <>
      <Header id="header">
        <HeaderInner>
          <CustomLink to="/"><StyledLogo type="full" /></CustomLink>
          <Hamburger onClick={toggleBodyClass} />
          <Close onClick={toggleBodyClass} />
          <Menu />
          <ButtonGroup>
            <FAQ to="vanliga-fragor">
              FAQ
              <SpeakingBubble />
            </FAQ>
            <MyPages
              to="https://marknad.kallfelt.se/mina-sidor/logga-in"
              target="_blank"
            >
              Mina sidor
              <ExternalLinkIcon />
            </MyPages>
          </ButtonGroup>
        </HeaderInner>
      </Header>
    </>
  )
}