import React from 'react'
import {
  ServiceButton,
  Wrapper,
  ButtonTextPrimary,
  ButtonTextSecondary
} from './styles'

const ServiceNotification = ({ className }) => {
  return (
    <ServiceButton
      className={className}
      type="link"
      text=""
      colorTheme="accent"
      to="https://marknad.kallfelt.se/mina-sidor/logga-in"
    >
      <Wrapper>
        <ButtonTextPrimary>Serviceanmälan</ButtonTextPrimary>
        <ButtonTextSecondary>Mina sidor</ButtonTextSecondary>
      </Wrapper>
    </ServiceButton>
  )
}

export default ServiceNotification