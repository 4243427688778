import React from 'react'
// import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import { 
  Halign,
  HalignContent,
  HalignArticle,
  Heading1,
  Heading2,
  Heading3,
  Paragraph,
  ParagraphSmall
 } from '../../layout/mixins'
import {
  TestBlock
} from './styles'
import Button from '../../components/Button'
import SpeachBubble from '../../components/SpeachBubble'

const Specimen = () => {
  return (
    <Layout>
      <Halign>
        <TestBlock>
          <Heading1>Halign</Heading1>
          <Paragraph>Suspendisse mollis pharetra pulvinar. Pellentesque ut eros elit. Duis vitae arcu congue risus dignissim ultricies. Duis quis bibendum lorem. Pellentesque volutpat sed quam ut hendrerit. Integer suscipit ipsum id neque lacinia consequat. Suspendisse sollicitudin efficitur justo, ut posuere ipsum lacinia eget. Donec efficitur, lorem vitae dignissim semper, turpis erat imperdiet quam, laoreet egestas dolor dui sed lacus. Duis sodales diam urna, non vulputate lacus vehicula ac.</Paragraph>
        </TestBlock>
      </Halign>
      <HalignContent>
        <TestBlock>
        <Heading2>Halign Content</Heading2>
          <Paragraph>Suspendisse mollis pharetra pulvinar. Pellentesque ut eros elit. Duis vitae arcu congue risus dignissim ultricies. Duis quis bibendum lorem. Pellentesque volutpat sed quam ut hendrerit. Integer suscipit ipsum id neque lacinia consequat. Suspendisse sollicitudin efficitur justo, ut posuere ipsum lacinia eget. Donec efficitur, lorem vitae dignissim semper, turpis erat imperdiet quam, laoreet egestas dolor dui sed lacus. Duis sodales diam urna, non vulputate lacus vehicula ac.</Paragraph>
        </TestBlock>
      </HalignContent>
      <HalignArticle>
        <TestBlock>
          <Heading3>Halign Article</Heading3>
          <Paragraph>Suspendisse mollis pharetra pulvinar. Pellentesque ut eros elit. Duis vitae arcu congue risus dignissim ultricies. Duis quis bibendum lorem. Pellentesque volutpat sed quam ut hendrerit. Integer suscipit ipsum id neque lacinia consequat. Suspendisse sollicitudin efficitur justo, ut posuere ipsum lacinia eget. Donec efficitur, lorem vitae dignissim semper, turpis erat imperdiet quam, laoreet egestas dolor dui sed lacus. Duis sodales diam urna, non vulputate lacus vehicula ac.</Paragraph>
        </TestBlock>
      </HalignArticle>
      <HalignArticle>
        <TestBlock>
          <Heading3>Halign Article with ParagraphSmall body text</Heading3>
          <ParagraphSmall>Suspendisse mollis pharetra pulvinar. Pellentesque ut eros elit. Duis vitae arcu congue risus dignissim ultricies. Duis quis bibendum lorem. Pellentesque volutpat sed quam ut hendrerit. Integer suscipit ipsum id neque lacinia consequat. Suspendisse sollicitudin efficitur justo, ut posuere ipsum lacinia eget. Donec efficitur, lorem vitae dignissim semper, turpis erat imperdiet quam, laoreet egestas dolor dui sed lacus. Duis sodales diam urna, non vulputate lacus vehicula ac.</ParagraphSmall>
        </TestBlock>
      </HalignArticle>
      <HalignContent>
        <TestBlock className="no-background">
        <Heading2>Button variations</Heading2>

          <Paragraph>Regular button without icon</Paragraph>
          <Button />

          <Paragraph>Regular button with icon</Paragraph>
          <Button
            icon="arrow-short"
          />

          <Paragraph>Accent button</Paragraph>
          <Button
            icon="arrow-short"
            theme="accent"
          />

          <Paragraph>Dark button</Paragraph>
          <Button
            icon="arrow-short"
            theme="dark"
          />

        </TestBlock>
      </HalignContent>
      <HalignContent>
        <TestBlock className="button-group">
          <Heading2>Button group</Heading2>
          <Button
            icon="arrow-short"
            theme="regular"
          />
          <Button
            icon="arrow-short"
            theme="accent"
          />
          <Button
            icon="arrow-short"
            theme="dark"
          />
        </TestBlock>
      </HalignContent>
      <HalignContent>
        <TestBlock className="speach-bubble no-background">
          <Heading2>Speach Bubble</Heading2>
          <SpeachBubble>Im aa speach bubble</SpeachBubble>
          <br />
          <br />
          <br />
          <SpeachBubble
            arrowPosition="right"
          >
            Im aa speach bubble
          </SpeachBubble>
        </TestBlock>
      </HalignContent>
    </Layout>
  )
}

export default Specimen

// export const query = graphql`
//   query IndexQuery {
//     datoCmsPage(id: {eq: "DatoCmsPage-1721668-en"}) {
//       pageBlocks {
//         ...HeroBlockFragment
//         ...TextBlockFragment
//         ...UspBlockFragment
//         ...ReportageHighlightBlockFragment
//         ...ThreedTourBlockFragment
//       }
//       seoMetaTags {
//         tags
//       }
//     }
//   }
// `
