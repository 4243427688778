import styled, { css } from 'styled-components'
import styledBreakpoint from '@humblebee/styled-components-breakpoint'
import theme from './theme'

export const breakpoint = styledBreakpoint({
  phone: theme.breakpoints.phone,
  tablet: theme.breakpoints.tablet,
  desktop: theme.breakpoints.desktop,
  large: theme.breakpoints.large,
  max: theme.breakpoints.max
})

export const HalignStyles = css`
  max-width: ${theme.maxWidth};
  margin: 0 auto;
  padding-left: ${theme.gutter};
  padding-right: ${theme.gutter};
`

export const Halign = styled.div`
  ${HalignStyles}
`

export const HalignContentStyles = css`
  max-width: ${theme.maxWidth};
  margin: 0 auto;
  padding-left: ${theme.gutterLarge};
  padding-right: ${theme.gutterLarge};

  ${breakpoint.up('tablet')`
    padding-left: calc(${theme.gutter} * 4);
    padding-right: calc(${theme.gutter} * 4);
  `}

  ${breakpoint.up('desktop')`
    padding-left: calc(${theme.gutter} * 7);
    padding-right: calc(${theme.gutter} * 7);
  `}
`

export const HalignContent = styled.div`
  ${HalignContentStyles}
`

export const HalignArticleStyles = css`
  max-width: ${theme.maxWidth};
  margin: 0 auto;
  padding-left: ${theme.gutter};
  padding-right: ${theme.gutter};

  ${breakpoint.up('tablet')`
    padding-left: calc(${theme.gutter} * 7);
    padding-right: calc(${theme.gutter} * 7);
  `}

  ${breakpoint.up('large')`
    padding-left: calc(${theme.gutter} * 15);
    padding-right: calc(${theme.gutter} * 15);
  `}
`

export const HalignArticle = styled.div`
  ${HalignArticleStyles}
`

export const BlockPaddingBottom = css`
  padding-bottom: ${theme.blockpaddingMobile};

  ${breakpoint.up('desktop')`
    padding-bottom: ${theme.blockpadding};
  `}
`

export const BlockPaddingTop = css`
  padding-top: ${theme.blockpaddingMobile};

  ${breakpoint.up('desktop')`
    padding-top: ${theme.blockpadding};
  `}
`

export const BlockPaddingVertical = css`
  padding-top: ${theme.blockpaddingMobile};
  padding-bottom: ${theme.blockpaddingMobile};

  ${breakpoint.up('desktop')`
    padding-top: ${theme.blockpadding};
    padding-bottom: ${theme.blockpadding};
  `}
`

export const BlockPadding = css`
  padding: ${theme.blockpaddingMobile};

  ${breakpoint.up('desktop')`
    padding: calc(${theme.blockpadding} * 2) ${theme.blockpadding};
  `}

`

export const Heading1Styles = css`
  font-family: ${theme.fontfamilyPrimary};
  font-size: 2.5rem;
  line-height: 1.1em;
  font-weight: 700;
  margin-bottom: 1rem;

  ${breakpoint.up('desktop')`
    font-size: 3.56rem;
  `}
`

export const Heading1 = styled.h1`
  ${Heading1Styles}
`

export const Heading2Styles = css`
  font-family: ${theme.fontfamilyPrimary};
  font-size: 1.786rem;
  line-height: 1.24em;
  font-weight: 700;
  margin-bottom: 1rem;

  ${breakpoint.up('desktop')`
    font-size: 2.5rem;
    line-height: 1.2em;
  `}
`

export const Heading2 = styled.h2`
  ${Heading2Styles}
`

export const Heading3Styles = css`
  font-family: ${theme.fontfamilyPrimary};
  font-size: 1.4rem;
  line-height: 1.24em;
  font-weight: 700;
  margin-bottom: 1rem;

  ${breakpoint.up('desktop')`
    font-size: 1.78rem;
    line-height: 1.43em;
  `}
`

export const Heading3 = styled.h3`
  ${Heading3Styles}
`

export const Heading4Styles = css`
  font-family: ${theme.fontfamilyPrimary};
  font-size: 1.33rem;
  line-height: 1.417em;
  font-weight: 700;
  margin-bottom: 1rem;
`

export const Heading4 = styled.h4`
  ${Heading4Styles}
`

export const ParagraphStyles = css`
  font-family: ${theme.fontfamilyPrimary};
  font-size: inherit;
  font-weight: 400;
  line-height: 1.45em;
  max-width: 55rem;
`

export const Paragraph = styled.p`
  ${ParagraphStyles}
`

export const ParagraphSmallStyles = css`
  font-family: ${theme.fontfamilyPrimary};
  font-size: 0.89rem;
  line-height: 1.375em;
  max-width: 55rem;
`

export const ParagraphSmall = styled.p`
  ${ParagraphSmallStyles}
`

export const NewsTextSmallStyles = css`
  font-family: ${theme.fontfamilyPrimary};
  font-size: 1.33rem;
  line-height: 1.2em;
`

export const NewsTextLargeStyles = css`
  font-family: ${theme.fontfamilyPrimary};
  font-size: 1.7rem;
  line-height: 1.2em;
`

export const truncateOverflow = (lines = '3') => css`
  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;  
  overflow: hidden;
`

export const hyphens = css`
  hyphens: auto;
  @supports not (hyphens: auto) {
    word-break: break-all;
  }
`