import React, { useState, useEffect } from 'react'
import {
  Wrapper,
  Heading,
  Form,
  ButtonGroup,
  StyledButton,
  SubmitMessage,
  // Consent,
  // ConsentContent,
  // CheckboxWrapper,
  // Checkbox,
  // CheckboxCheck,
  Label,
  Message,
  Close,
  Content
} from './styles'
import {
  ERROR_SUBMIT_INQUIRY,
  SUCCESS_SUBMIT_INQUIRY,
  TERMS_AGREEMENT
} from '../../content'
import validateInputs from '../../utils/validateInputs'
import Input from '../Input'

const Inquiry = () => {
  const initialValues = {
    name: '',
    address: '',
    email: '',
    additionalmessage: '',
    phone: '',
    // consent: false
  }

  const [values, setValues] = useState(initialValues)

  const [messages, setMessages] = useState({
    name: false,
    address: false,
    email: false,
    additionalmessage: false,
    phone: false,
    // consent: false
  })

  const [loading, setLoading] = useState(false)

  const [submitStatus, setSubmitStatus] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    // Honeypot for bots
    if (values.address !== '') {
      return
    }

    delete values.address

    const errors = validateInputs(values)

    if (!errors) {
      // Execute submit logic
      // Todo: Refactor to external request service
      // Todo: Add loding state

      setLoading(true)

      try {
        const response = await fetch('/.netlify/functions/inquiry', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(values)
        })
        const data = await response.text()

        if (data === 'success') {
          // Show success message and reset form on successful submissiom
          setSubmitStatus('success')
          setValues(initialValues)
          setLoading(false)
        } else {
          setSubmitStatus('error')
          setLoading(false)
        }
      } catch (error) {
        setSubmitStatus('error')
        setLoading(false)
      }
    }
    setMessages(errors)
  }

  const handleChange = (target) => {
    const {
      name, value, checked, type
    } = target
    const newValue = type === 'checkbox' ? checked : value

    setValues({
      ...values,
      [name]: newValue
    })
  }

  return (
    <Wrapper>
      <Content id="inquiry">
        <Form>
          <Heading>Kontakta oss</Heading>
          <Input
            label="Namn*"
            name="name"
            onChange={handleChange}
            value={values.name}
            message={messages.name}
          />
          <Input
            label="Epost*"
            name="email"
            onChange={handleChange}
            value={values.email}
            message={messages.email}
          />
          <Input
            label="Telefon*"
            name="phone"
            onChange={handleChange}
            value={values.phone}
            message={messages.phone}
          />
          <Input
            label="Adress"
            name="address"
            onChange={handleChange}
            value={values.address}
            message={messages.address}
            display={false}
          />
          <Input
            id="message"
            type="textarea"
            label="Meddelande"
            name="additionalmessage"
            onChange={handleChange}
            value={values.additionalmessage}
            message={messages.additionalmessage}
            theme="dark"
          />
          {/* <Consent>
            <ConsentContent>
              <CheckboxWrapper>
                <Checkbox
                  type="checkbox"
                  id="terms-agreement"
                  onChange={(e) => handleChange(e.target)}
                  name="consent"
                  value={values.consent}
                />
                <CheckboxCheck />
              </CheckboxWrapper>
              <Label htmlFor="terms-agreement">{TERMS_AGREEMENT}</Label>
            </ConsentContent>
            {messages.consent && <Message type={messages.consent.type}>{messages.consent.text}</Message>}
          </Consent> */}
          <ButtonGroup>
            <StyledButton
              text={loading ? 'Skickar...' : 'Skicka'}
              onClick={(e) => handleSubmit(e)}
              loading={loading}
            />
            {submitStatus === 'error' && <SubmitMessage type="error">{ERROR_SUBMIT_INQUIRY}</SubmitMessage>}
            {submitStatus === 'success' && <SubmitMessage type="success">{SUCCESS_SUBMIT_INQUIRY}</SubmitMessage>}
          </ButtonGroup>
        </Form>
      </Content>
    </Wrapper>
  )
}

export default Inquiry