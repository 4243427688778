import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import {
  Grid, StyledQuote
} from './styles'

const Quotes = ({ data }) => (
  <>
    <Grid>
      {data.map((quote, index) => (
        <StyledQuote
          icon="arrow"
          {...quote}
        />
      ))}
    </Grid>
  </>
)

Quotes.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
      address: PropTypes.string,
    }).isRequired
  ).isRequired
}

export default Quotes

export const query = graphql`
  fragment QuoteFragment on DatoCmsQuote {
    text
    author
    address
  }
`