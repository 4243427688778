/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { graphql } from 'gatsby'
import {
  AboutSection
} from './styles'
import TextWithImage from '../../components/TextWithImage'
import Quotes from '../../components/Quotes'
import Section from '../../components/Section/section'
import Announcement from '../../components/Announcement/announcement'

const About = ({ data }) => {
  const {
    datoCmsAnnouncement: {
      announcement: [announcement]
    },
    datoCms: {
      hero: [hero],
      content,
      quotes
    }
  } = data

  return (
    <>
      <Section
        horizontalPadding="max"
        verticalPadding="none"
        fullMobile
      >
        <TextWithImage
          {...hero}
          headingSize={1}
          addShortcut={!announcement.activateAnnouncement}
          asHero
        />
      </Section>
      {announcement.activateAnnouncement && (
        <Section
          horizontalPadding="max"
          verticalPadding="none"
          fullMobile
        >
          <Announcement />
        </Section>
      )}
      <Section
        verticalPadding="top"
      >
        <Quotes data={quotes} />
      </Section>
      {content.map((module, index) => (
        <AboutSection
          className="about-section"
          verticalPadding="top"
          fullMobile
        >
          <TextWithImage
            key={`${module.title}-${index}`}
            {...module}
          />
        </AboutSection>
      ))}
    </>
  )
}

export default About

export const query = graphql`
  query {
    datoCmsAnnouncement {
      announcement: notice {
        activateAnnouncement
      }
    }       
    datoCms: datoCmsAbout {
        hero {
          ...TextWithImageFragment
        }
        quotes {
          ...QuoteFragment
        }
        content {
          ...TextWithImageFragment
        }
        seo: seoMetaTags {
          tags
        }
      }
  }
`
