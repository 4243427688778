import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import {
  isExternalUrl,
  isHashUrl
} from '../../utils/helpers'

/**
 * Link that also works for external URL's
 */

const CustomLink = (props) => {
  
  let {
    to
  } = props
  const {
    className,
    children,
    target
  } = props

  if (isExternalUrl(to)) {
    return (
      <a
      href={to}
      target="_blank"
      className={className}
    >
      {children}
    </a>
    )
  }

  if (isHashUrl(to)) {
    return (
      <a
      href={to}
      target={target}
      className={className}
    >
      {children}
    </a>
    )
  }

  if(to && !to.startsWith('/')){
    to = `/${to}`
  }

  return <Link {...props} to={to} />
}

CustomLink.propTypes = {
  to: PropTypes.string.isRequired
}

export default CustomLink