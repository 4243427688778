import React from 'react'
import theme from '../../layout/theme'
import {
  trimUnits,
  firstLetterUppercase
} from '../../utils/helpers'
import {
  Grid,
  FeedItem,
  ItemImage,
  DatoItemImage,
  Content,
  Tags,
  Title,
  Plus,
  ModalContent,
  TextItem,
  Question,
  Answer,
  ImageItem
} from './styles'
const {
  breakpoints: {
    phone,
    tablet,
    large
  },
  gutter
} = theme

const sizes = `
  (max-width: ${phone}px) calc(100vw - ${trimUnits(gutter) * 2}rem),
  (max-width: ${tablet}px) calc((100vw - ${15 * trimUnits(gutter)}rem) / 2), 
  (max-width: ${large}px) calc((100vw - ${16 * trimUnits(gutter)}rem) / 3), 
  478px
`

export const addSizes = (fluid) => {
  return {
    ...fluid,
    sizes
  }
}

import Toggle from '../../components/Toggle'
import Modal from '../../components/Modal'
import Section from '../../components/Section/section'

const FAQFeed = ({ faqs, isResultFiltered }) => (
  <Grid>
    {faqs.map(({
      question,
      answer,
      slug,
      id,
      category,
      image
    }) => (
      <Toggle key={id}>
        {(on, toggle) => (
          <>
            <FeedItem
              className={image && 'has-image'}
              key={id}
              onClick={toggle}
            >
              <Content>
                <Tags>{category.title}</Tags>
                <Title>{firstLetterUppercase(question)}</Title>
                <Plus name="close" />
              </Content>
            </FeedItem>

            {on && (
              <Modal toggle={toggle}>
                <Section
                  horizontalPadding="content"
                  verticalPadding="none"
                >
                  <ModalContent>
                    <TextItem>
                      <Question>{question}</Question>
                      <Answer
                        className="rte"
                        dangerouslySetInnerHTML={{ __html: answer }}
                      />
                    </TextItem>
                    <ImageItem>
                      {image && !isResultFiltered ? <ItemImage fluid={addSizes(image.fluid)} /> : null}
                      {image && isResultFiltered ? <DatoItemImage data={image?.responsiveImage} /> : null}
                    </ImageItem>
                  </ModalContent>
                </Section>
              </Modal>
            )}
          </>
        )}
      </Toggle>
    ))}
  </Grid>
)

export default FAQFeed