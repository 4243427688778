import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { ThemeProvider } from 'styled-components'
import { themes } from '../Button/styles'
import {
  Wrapper
} from './styles'
import CustomLink from '../CustomLink'

const CookieBar = () => (
  <ThemeProvider theme={themes.regular}>
    <Wrapper>
      <CookieConsent
        disableStyles
        contentClasses="cookieConsentContent"
        buttonClasses="cookieConsentButton"
        buttonText="OK"
      >
        Vi använder cookies (kakor) på sajten för att ge dig en så bra upplevelse som möjligt av vår webbplats. <strong><CustomLink to="/gdpr">Läs mer om cookies här.</CustomLink></strong>
        {' '}
      </CookieConsent>
    </Wrapper>
  </ThemeProvider>
)

export default CookieBar