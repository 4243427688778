/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { node } from 'prop-types'
import {
  Grid,
  InfoItem,
  FormItem,
  InfoTag,
  Title,
  AddressTitle,
  PostalAddress,
  VisitingAddress,
  PhoneLink,
  EmailLink,
  StyledIcon,
  LinkList,
  Filter,
  Center,
  Category,
  Container
} from './styles'
import Section from '../../components/Section/section'
import InformationRow from '../../components/InformationRow'
import Inquiry from '../../components/Inquiry'
import Announcement from '../../components/Announcement/announcement'
import EmployeeCard from '../../components/EmployeeCard'

const Contact = ({ data }) => {
  const {
    datoCmsAnnouncement: {
      announcement: [announcement]
    },
    datoCms: {
      title,
      address: [address],
      informationRow
    },
    categories: {
      edges: categories
    },
    employees: {
      edges: employees
    }
  } = data

  // Category filter state
  const [activeCategory, setActiveCategory] = useState('all')
  const [filteredEmployees, SetFilteredEmployees] = useState(employees)

  const handleCategoryChange = (category) => {
    if (category === activeCategory) return
    setActiveCategory(category)
  }

  // Subscribe to active category change
  useEffect(() => {
    if (!activeCategory) return

    if (activeCategory === 'all') {
      SetFilteredEmployees(employees)
    } else {
      const filtered = employees.filter((employee) => employee.node.category.originalId === activeCategory)
      SetFilteredEmployees(filtered)
    }
  }, [activeCategory])

  return (
    <>
      <Section
        horizontalPadding="content"
        verticalPadding="top"
      >
        <Grid>
          <InfoItem>
            <InfoTag>Kontakt</InfoTag>
            <Title>{title}</Title>
            <AddressTitle>Postadress:</AddressTitle>
            <PostalAddress
              className="rte"
              dangerouslySetInnerHTML={{ __html: address.postalAddress }}
            />
            <AddressTitle>Besöksadress</AddressTitle>
            <VisitingAddress
              className="rte"
              dangerouslySetInnerHTML={{ __html: address.visitingAddress }}
            />

            <LinkList>
              <PhoneLink href={`tel:${address.telephone}`}>
                <StyledIcon name="arrow-short" />
                {address.telephone}
              </PhoneLink>
              <EmailLink href={`mailto:${address.eMail}`}>
                <StyledIcon name="arrow-short" />
                {address.eMail}
              </EmailLink>
            </LinkList>
          </InfoItem>
          <FormItem>
            <Inquiry />
          </FormItem>
        </Grid>
      </Section>
      <Section
        horizontalPadding="content"
      >
        <InformationRow items={informationRow} />
      </Section>
      {announcement.activateAnnouncement && (
        <Section
          horizontalPadding="full"
          verticalPadding="top"
          fullMobile
        >
          <Announcement />
        </Section>
      )}

      <Section
        horizontalPadding="content"
      >
        <Center><Title>Våra medarbetare</Title></Center>
        <Filter>
          <Category
            key="all-filter"
            onClick={() => handleCategoryChange('all')}
            active={activeCategory === 'all'}
          >
              Alla
          </Category>
          {categories.map(({ node }) => (
            <Category
              key={node.originalId}
              onClick={() => handleCategoryChange(node.originalId)}
              active={node.originalId === activeCategory}
            >
              {node.title}
            </Category>
          ))}
        </Filter>
        <Container>
          {filteredEmployees.map((employee) => {
            return (
              <EmployeeCard
                {...employee.node}
              />
            )
          })}
        </Container>
      </Section>
    </>
  )
}

export default Contact

export const query = graphql`
  query {
    datoCmsAnnouncement {
      announcement: notice {
        activateAnnouncement
      }
    }      
    categories: allDatoCmsEmployeeCategory(sort: {fields: position}) {
        edges {
          node {
            title
            originalId
          }
        }
      }
    employees: allDatoCmsEmployee(sort: {fields: position}) {
      edges {
        node {
          id
          name
          email
          phone
          additionalInformation
          title
          category {
            title
            originalId
          }
          image {
            fluid(maxWidth: 956, sizes: "347, 694, 274, 548, 395, 478, 956") {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }      
    datoCms: datoCmsContact {
      title
      address {
        eMail
        postalAddress
        visitingAddress
        telephone
      }
      informationRow {
        type: __typename
        ...InformationLinkFragment
        ...InformationTelephoneFragment
        ...InformationLinkPhoneFragment
      }
      seo: seoMetaTags {
        tags
      } 
    }
  }
`