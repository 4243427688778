import { trimUnits } from '../utils/helpers'

class Theme {
  constructor() {
    this.colorAccent = '#BB1F23'
    this.colorBlack = '#1E1F1D'
    this.colorWhite = '#FFF'
    this.colorGrey = '#EAEAEA'
    this.colorGreen = '#65B65C'
    this.colorYellow = '#F1BF4F'
    this.colorLinkhover = '#999'
    this.colorDanger = 'red'

    this.backgroundColorPrimary = '#FFF'
    this.backgroundColorSecondary = '#F9F9F9'

    this.textcolorPrimary = this.colorBlack
    this.textcolorSecondary = this.colorWhite

    this.colorHr = '#E5E5E5'

    this.gridUnit = '5.5rem'
    this.gutter = '1rem'
    this.gutterSmall = `${trimUnits(this.gutter) / 2}rem`
    this.gutterMedium = `${trimUnits(this.gutter) * 1.5}rem`
    this.gutterLarge = `${trimUnits(this.gutter) * 2}rem`
    this.gutterXLarge = `${trimUnits(this.gutter) * 3}rem`
    this.gutterXXLarge = `${trimUnits(this.gutter) * 4}rem`

    this.iconSize = '4rem'
    this.buttonIconSize = '1.2em'

    this.maxWidth = '1700px'

    this.headerheightMobile = '60px'
    this.headerheight = '3.5rem'

    this.blockpaddingMobile = this.gutterXLarge
    this.blockpadding = `${trimUnits(this.gutterXXLarge) * 1.5}rem`

    this.fontfamilyPrimary = 'Greycliff CF, sans-serif'
    this.fontfamilySecondary = 'Jigsaw Stencil Light'

    this.fontsizeMobile = '14px'
    this.fontsizeResponsive = 'calc(12px + 0.35vw)'
    this.fontsizeRoot = '18px'
    this.fontsizeSmall = '0.7em'

    this.lineheightRegular = '1.4em'
    this.lineheightSmall = '1.1em'

    this.buttonRadius = '5px'
    this.contentRadius = '8px'
    this.mapRadius = '20px'
    this.imageRadius = '10px'

    this.boxShadow = '0px 6px 10px rgba(0, 0, 0, 0.1)'

    this.zLevel1 = '1'
    this.zLevel2 = '2'
    this.zLevel3 = '3'
    this.zLevel4 = '4'

    this.transitionFast = '0.2s'
    this.transitionRegular = '0.3s'
    this.transitionSlow = '1s'

    this.breakpoints = {
      min: 0,
      phone: 668,
      tablet: 768,
      desktop: 1024,
      large: 1280,
      max: parseInt(trimUnits(this.maxWidth), 10)
    }
  }
}

const theme = new Theme()

export default theme