import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { getNewsByTag } from '../../lib/CMSFetchService'
import {
  Hero,
  HeroContent,
  Title,
  Filter,
  Category,
  Container
} from './styles'
import Section from '../../components/Section/section'
import NewsFeed from '../../components/NewsFeed/newsFeed'
import Button from '../../components/Button'

const News = ({ data }) => {

  const {
    heroTitle
  } = data.datoCmsNewsPage

  const {
    edges: categories
  } = data.categories

  const {
    totalCount: totalCountUnfiltered,
    edges: allArticles
  } = data.allArticles

  const [activeCategory, setActiveCategory] = useState(null)
  const [articles, setArticles] = useState(allArticles.map(({ node }) => node))
  const [isResultFiltered, setIsResultFiltered] = useState(false)
  const [isCategoryLoading, setIsCategoryLoading] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [totalCount, setTotalCount] = useState(totalCountUnfiltered)
  const [firstBatch] = useState(4)
  const [subsequentBatches] = useState(9)

  // Subscribe to active category change
  useEffect(() => {
    // Fetch news articles based on active category
    // but not on inital render
    if (!activeCategory) return
    setIsCategoryLoading(true)
    getNewsByTag(activeCategory, getFirstValue(), getSkipValue())
      .then((response) => {
        setArticles(response.data.allNews)
        setTotalCount(response.data.count)
        setIsResultFiltered(true)
        setIsCategoryLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [activeCategory])

  // Subscribe to page change
  useEffect(() => {
    // Fetch news articles with incremented page variable
    if (!isResultFiltered) return
    setIsPageLoading(true)
    getNewsByTag(activeCategory, getFirstValue(), getSkipValue())
      .then((response) => {
        setArticles([...articles, ...response.data.allNews])
        setIsPageLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [page])

  const handleCategoryChange = (category) => {
    if (category === activeCategory) return
    setPage(1)
    setArticles([])
    setActiveCategory(category)
  }

  const handlePageIncrement = () => {
    if (articles.length < totalCount) {
      setPage(page + 1)
    }
  }

  const getFirstValue = () => {
    if (page > 1) return subsequentBatches
    return firstBatch
  }

  const getSkipValue = () => {
    if (page > 1) {
      return firstBatch + ((page - 2) * subsequentBatches)
    }
    return 0
  }

  return (
    <>
      <Hero>
        <HeroContent>
          <Title>{heroTitle}</Title>
          <Filter>
            <Category
              key="all-filter"
              onClick={() => handleCategoryChange('all')}
              active={activeCategory === 'all'}
            >
              Alla
            </Category>

            {categories.map(({ node }) => (
              <Category
                key={node.originalId}
                onClick={() => handleCategoryChange(node.originalId)}
                active={node.originalId === activeCategory}
              >
                {node.title}
              </Category>
            ))}
          </Filter>
        </HeroContent>
      </Hero>
      <Section>
        <Container>
          {isCategoryLoading && 'Läser in nyheter...'}
          {!isCategoryLoading && (
            <NewsFeed
              articles={articles}
              isResultFiltered={isResultFiltered}
            />
          )}
        </Container>
      </Section>
      {articles.length < totalCount && (
        <Section
          verticalPadding="bottom"
          alignContent="center"
        >
          <Button
            text={isPageLoading ? 'Hämtar...' : 'Läs in fler'}
            onClick={handlePageIncrement}
          />
        </Section>
      )}
    </>
  )
}

export default News

export const query = graphql`
  query {
    datoCmsNewsPage {
      id
      slug
      heroTitle
      seo: seoMetaTags {
        tags
      }
    }
    categories: allDatoCmsNewsCateogry(sort: {fields: position}) {
      edges {
        node {
          title
          originalId
        }
      }
    }
    allArticles: allDatoCmsNews(sort: {fields: meta___createdAt, order: DESC}) {
      totalCount
      edges {
        node {
          title
          slug
          meta {
            createdAt
          }
          category {
            title
          }
          image {
            fluid(maxWidth: 956, sizes: "347, 694, 274, 548, 395, 478, 956") {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`