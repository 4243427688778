import styled from 'styled-components'
import siteTheme from '../../layout/theme'
import { breakpoint } from '../../layout/mixins'

export const InputGroup = styled.div`
  position: relative;
  margin-bottom: ${siteTheme.gutterLarge};
  width: 100%;
  border: 1px solid ${({ theme }) => theme.textcolorPrimary};
  ${({ display }) => !display && 'display: none;'}

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${({ theme }) => theme.textColor};
  }
`

export const StyledInput = styled.input`
  font-family: ${({ theme }) => theme.fontfamilyPrimary};
  width: 100%;
  font-size: 1.11em;
  line-height: 1.45em;
  padding: 0.5rem 0;
  padding-left: ${({ theme }) => theme.gutterSmall};
  outline: none;
  color: ${({ theme }) => theme.textcolorPrimary};

  &::-webkit-scrollbar {
    display: none;
  }
`

export const TextAreaGroup = styled.div`
  position: relative;
  margin-bottom: ${siteTheme.gutterLarge};
  ${({ display }) => !display && 'display: none;'}
`

export const StyledTextArea = styled.textarea`
  font-family: ${({ theme }) => theme.fontfamilyPrimary};
  width: 100%;
  height: 10em;
  font-size: 1.11em;
  line-height: 1.45em;
  outline: none;
  color: ${({ theme }) => theme.textcolorPrimary};
  border: 1px solid ${({ theme }) => theme.textcolorPrimary};
  resize: none;
  padding: ${({ theme }) => theme.gutter};
`

export const Label = styled.label`
  position: absolute;
  top: 50%;
  left: ${({ theme }) => theme.gutterSmall};
  transform: translateY(-50%);
  transition: transform 0.25s ease-out;
  transform-origin: 0% 0%;
  font-size: 1.11em;
  line-height: 1.45em;
  color: ${({ theme }) => theme.textcolorPrimary};
  pointer-events: none;

  ${({ isFocused }) => isFocused && `
    transform: translate(-0.5rem, -160%) scale(0.7);
  `}

  ${TextAreaGroup} & {
    position: static;
    display: block;
    margin-bottom: 0.5rem;
    transform: none;
    pointer-events: all;

    // position: absolute;
    top: 0.5rem;
  }
`

export const StyledMessage = styled.span`
  position: absolute;
  right: 0;
  bottom: 0.5rem;
  font-size: 14px;

  ${({ theme, type }) => type === 'error' && `
    color: ${theme.colorAccent};
  `}

  ${({ theme, type }) => type === 'success' && `
    color: ${theme.colorAccent};
  `}
`

export const themes = {
  light: {
    textColor: siteTheme.colorBlack,
    backgroundColor: siteTheme.colorWhite
  },
  dark: {
    textColor: siteTheme.colorWhite,
    backgroundColor: siteTheme.colorBlack
  }
}