import { fetchHeaders } from '.'
import KallfeltError from '../KallfeltError'

export default async function getFaqsBySearchTerm(searchTerm, first, skip) {
  const RESPONSIVE_IMAGE_FRAGMENT = `
    srcSet
    webpSrcSet
    sizes
    src
    width
    height
    aspectRatio
    alt
    title
    bgColor
    base64
  `

  const FAQ_ITEM_FRAGMENT = `
    id
    question
    answer
    slug
    createdAt
    category {
      title
    }
    image {
      responsiveImage(imgixParams: { fit: crop, w: 470, h: 758 }) {
        ${RESPONSIVE_IMAGE_FRAGMENT}
      }
    }
  `

  const PAGINATION_FRAGMENT = `first: ${first}, skip: ${skip}`
  const SORT_FRAGMENT = 'orderBy: _createdAt_DESC'
  const FILTER_FRAGMENT = `filter: {
    OR: [
      { question: { matches: { pattern: "${searchTerm}" } } },
      { answer: { matches: { pattern: "${searchTerm}" } } }
    ]
  }`

  const FILTER_QUERY = `
    allFaqs(${SORT_FRAGMENT}, ${PAGINATION_FRAGMENT}, ${FILTER_FRAGMENT}) {
      ${FAQ_ITEM_FRAGMENT}
    }
    _allFaqsMeta(${FILTER_FRAGMENT}) {
      count
    }
  `
  const response = await fetch(
    process.env.GATSBY_DATO_GRAPHQL_ENDPOINT,
    fetchHeaders(`{${FILTER_QUERY}}`)
  )
  const result = await response.json()

  if (result.errors) {
    throw new KallfeltError('validation', 'Något gick fel. Försök igen senare.')
  }

  const modifiedItems = result.data.allFaqs.map((item) => {
    return {
      ...item,
      meta: {
        createdAt: item.createdAt
      }
    }
  })

  return {
    data: {
      allFaqs: modifiedItems,
      count: result.data._allFaqsMeta.count
    }
  }
}