import React from 'react'
import {
  Nav,
  StyledHalign,
  Content,
  NavList,
  NavItem,
  ButtonGroup,
  StyledSpeachBubble,
  StyledServiceNotification
} from './styles'
import CustomLink from '../CustomLink'
import Button from '../Button'

const MenuMobile = ({ menuItems }) => (
  <Nav>
    <StyledHalign>
      <Content>
        <NavList>
          {menuItems.map(({ link }) => (
            <NavItem
              key={link.title}
              onClick={() => {
                document
                  .querySelector('body')
                  .classList
                  .remove('menu-open')
              }}
            >
              <CustomLink to={link.slug} activeClassName="active-link">
                {link.title}
              </CustomLink>
            </NavItem>
          ))}
        </NavList>
        <ButtonGroup>
          <Button
            type="link"
            to="https://marknad.kallfelt.se/mina-sidor/logga-in"
            colorTheme="light"
            text="Mina sidor"
            hoverEffect={false}
          />
          <Button
            type="link"
            to="vanliga-fragor"
            colorTheme="light"
            text="FAQ"
            hoverEffect={false}
          />
          <StyledServiceNotification />
        </ButtonGroup>
      </Content>
    </StyledHalign>
  </Nav>
)

MenuMobile.defaultProps = {
  menuItems: [
    {
      link: {
        title: 'Bostad',
        slug: '/bostad'
      }
    },
    {
      link: {
        title: 'Lokal',
        slug: '/lokal'
      }
    },
    {
      link: {
        title: 'Om oss',
        slug: '/om-oss'
      }
    },
    {
      link: {
        title: 'Publikationer',
        slug: '/publikationer'
      }
    },
    {
      link: {
        title: 'Kontakt',
        slug: '/kallfelt-byggnads-ab'
      }
    }
  ]
}

export default MenuMobile