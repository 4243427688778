import styled from 'styled-components'
import {
  breakpoint
} from '../../layout/mixins'
import ArrowIcon from '../../layout/icons/arrow-short.svg'
import CustomLink from '../../components/CustomLink'

export const Article = styled.article`
  margin-bottom: ${({ theme }) => theme.gutterLarge};
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colorAccent};
  margin-bottom: ${({ theme }) => theme.gutterLarge};
`

export const Pagination = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${breakpoint.up('phone')`
    flex-direction: row;
  `}
`

export const Prev = styled(CustomLink)`
  display: flex;
  width: 100%;
  order: 2;
  padding-bottom: ${({ theme }) => theme.gutterLarge};
  padding-top: ${({ theme }) => theme.gutterLarge};
  border-bottom: 1px solid ${({ theme }) => theme.colorAccent};

  ${breakpoint.up('phone')`
    order: 1;
    width: 50%;
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: 0;
    border: none;
    padding-right: ${({ theme }) => theme.gutter};
  `}
`

export const Next = styled(CustomLink)`
  display: flex;
  width: 100%;
  order: 1;
  padding-bottom: ${({ theme }) => theme.gutterLarge};
  border-bottom: 1px solid ${({ theme }) => theme.colorAccent};

  ${breakpoint.up('phone')`
    order: 2;
    width: 50%;
    padding-bottom: 0;
    padding-left: ${({ theme }) => theme.gutter};
    margin-bottom: 0;
    border: none;
    justify-content: flex-end;
  `}  
`

export const PaginationTitle = styled.div`
  font-size: 1.1rem;
  line-height: 1.2em;
  font-weight: bold;

  ${Prev} & {
    ${breakpoint.up('phone')`
      margin-left: ${({ theme }) => theme.gutter}; 
    `}
  }

  ${Next} & {
    ${breakpoint.up('phone')`
      margin-right: ${({ theme }) => theme.gutter}; 
    `}
  }
`

export const Arrow = styled(ArrowIcon)`
  width: 1em;
  height: 1em;
  fill: ${({ theme }) => theme.colorBlack};
  flex-shrink: 0;
  margin-left: auto;

  ${breakpoint.up('phone')`
    margin-left: 0;
  `}  
`

export const ArrowRight = styled(Arrow)`
  transform: translateY(0.1rem);
`

export const ArrowLeft = styled(Arrow)`
  transform: translateY(0.1rem) rotate(180deg);
  display: none;

  ${breakpoint.up('phone')`
    display: block;
  `}  
`

export const ArrowLeftMobile = styled(Arrow)`
  transform: translateY(0.1rem) rotate(180deg);

  ${breakpoint.up('phone')`
    display: none;
  `}  
`