import React from 'react'
import theme from '../../layout/theme'
import { Image } from 'react-datocms'
import {
  trimUnits,
  trimZeros,
  formatDate,
  firstLetterUppercase
} from '../../utils/helpers'
import {
  Grid,
  FeedItem,
  ItemImage,
  DatoItemImage,
  Gradient,
  Content,
  Tags,
  StyledDate,
  Title
} from './styles'

const NewsFeed = ({ articles, isResultFiltered }) => {
  const {
    breakpoints: {
      phone,
      tablet,
      large
    },
    gutter
  } = theme

  const sizes = `
    (max-width: ${phone}px) calc(100vw - ${trimUnits(gutter) * 2}rem),
    (max-width: ${tablet}px) calc((100vw - ${15 * trimUnits(gutter)}rem) / 2), 
    (max-width: ${large}px) calc((100vw - ${16 * trimUnits(gutter)}rem) / 3), 
    478px
  `

  const addSizes = (fluid) => {
    return {
      ...fluid,
      sizes
    }
  }

  return (
    <Grid>
      {articles.map(({
        title,
        slug,
        category,
        image,
        meta
      }) => (
        <FeedItem
          className={image && 'has-image'}
          key={meta.createdAt}
          to={`/nyheter/${slug}`}
        >
          {image && !isResultFiltered ? <ItemImage fluid={addSizes(image.fluid)} /> : null}
          {image && isResultFiltered ? <DatoItemImage data={image?.responsiveImage} /> : null}
          {image && <Gradient />}
          <Content>
            <Tags>{category[0].title}</Tags>
            <StyledDate>{formatDate(meta.createdAt)}</StyledDate>
            <Title>{title}</Title>
          </Content>
        </FeedItem>
      ))}
    </Grid>
  )
}

export default NewsFeed