import React from 'react'
import PropTypes from 'prop-types'
import {
  Wrapper, Text, Footer, FooterText, Pin, Arrow
} from './styles'

const Quote = ({
  text, author, address, className, icon
}) => {
  const ConditionalIcon = () => {
    if (icon === 'arrow') {
      return <Arrow />
    }
    if (icon === 'pin') {
      return <Pin />
    }
    return null
  }

  return (
    <>
      <Wrapper className={className}>
        <Text>{text}</Text>
        {author && address && (
        <Footer>
          <ConditionalIcon />
          <FooterText>
            {author}
            {address ? (
              <>
                <br />
                {address}
              </>
            ) : null}
          </FooterText>
        </Footer>
        )}
      </Wrapper>
    </>
  )
}

Quote.propTypes = {
  text: PropTypes.string.isRequired,
  author: PropTypes.string,
  address: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['pin', 'arrow'])
  ])
}

Quote.defaultProps = {
  author: null,
  address: null,
  className: null,
  icon: false
}

export default Quote