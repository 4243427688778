import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FullLogoIcon from '../../layout/icons/logo-full.svg'
import SmallLogoIcon from '../../layout/icons/logo-small.svg'
import { breakpoint } from '../../layout/mixins'

export const StyledFullLogo = styled(FullLogoIcon)`
  width: 121px;
  height: 35px;
  display: block;
 
  ${breakpoint.up('tablet')`
    width: 155px;
    height: 45px   
  `}
`

export const StyledSmallLogo = styled(SmallLogoIcon)`
  height: 3rem;
  display: block;
`

const Logo = ({ className, type }) => {
  if (type === 'full') {
    return (
      <StyledFullLogo className={className} />
    )
  }

  if (type === 'small') {
    return (
      <StyledSmallLogo className={className} />
    )
  }
}

export default Logo

Logo.propTypes = {
  type: PropTypes.string,
}

Logo.defaultProps = {
  type: 'full'
}