import React, { useState } from 'react'
import PropTypes from 'prop-types'
import GoogleMapReact from 'google-map-react'
import {
  Map,
  Marker,
  Title,
  StyledMarker,
  InfoBox,
  InfoGrid,
  TextItem,
  Description,
  ImageItem,
  StyledImage,
  ReadMore
} from './styles'
// import { fitBounds } from 'google-map-react/utils'
import { GOOGLE_MAPS_API_KEY } from '../../config'
// const scrollToElement = require('scroll-to-element');
import { withTheme } from 'styled-components'
import createMapOptions from '../../utils/createMapOptions'
import { POSITION } from '../../config'

const AreaMap = ({ areas }) => {
  const K_SIZE = 40

  const [API, setAPI] = useState(false)

  // // Save our GMAP information
  const handleAPI = (map, maps) => {
    setAPI({
      map,
      maps
    })
  }

  const center = areas.length > 1 ? POSITION : [areas[0].mapDetails?.latitude, areas[0].mapDetails?.longitude]

  return (
    <Map>
      <GoogleMapReact
        style={{ height: '100%' }}
        bootstrapURLKeys={{
          key: GOOGLE_MAPS_API_KEY
        }}
        defaultCenter={center}
        defaultZoom={12}
        options={createMapOptions}
        hoverDistance={K_SIZE / 2}
        onGoogleApiLoaded={({ map, maps }) => handleAPI(map, maps)}
      >
        {areas.map((area) => {
          return (
            <Marker
              key={area.title}
              lat={area?.mapDetails?.latitude}
              lng={area?.mapDetails?.longitude}
            >
              <InfoBox>
                <InfoGrid>
                  <TextItem>
                    <Title>{area?.title}</Title>
                    <Description
                      className="rte"
                      dangerouslySetInnerHTML={{ __html: area?.areaHero?.text }}
                    />
                    <ReadMore
                      to={`/omraden/${area?.slug}`}
                    >
                      Läs mer
                    </ReadMore>
                  </TextItem>
                  <ImageItem>
                    <StyledImage
                      fluid={area?.areaHero?.image?.fluid}
                    />
                  </ImageItem>
                </InfoGrid>
              </InfoBox>
              <StyledMarker />
            </Marker>
          )
        })}
      </GoogleMapReact>
    </Map>
  )
}

export default withTheme(AreaMap)

AreaMap.propTypes = {
  areas: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string.isRequired,
    areaHero: PropTypes.shape({
      title: PropTypes.string.isRequired,
      textContent: PropTypes.string.isRequired,
      backgroundImage: PropTypes.shape({
        fluid: PropTypes.shape({
          aspectRatio: PropTypes.number.isRequired,
          base64: PropTypes.string.isRequired,
          sizes: PropTypes.string.isRequired,
          src: PropTypes.string.isRequired,
          srcSet: PropTypes.string.isRequired
        }).isRequired
      }).isRequired
    }),
    mapDetails: PropTypes.shape({
      latitude: PropTypes.string.isRequired,
      longitude: PropTypes.string.isRequired
    })
  })).isRequired
}