import React from 'react'
import {
  Wrapper,
  StyledLinkIcon
} from './styles'

const ExternalLink = ({ className }) => {
  return (
    <Wrapper className={className}>
      <StyledLinkIcon />
    </Wrapper>
  )
}

export default ExternalLink