import React from 'react'
import PropTypes from 'prop-types'
import {
  Wrapper,
  Text
} from './styles'

const SpeachBubble = (props) => {

  const {
    text,
    arrowPosition
  } = props

  return (
    <Wrapper {...props}>
      <Text>{props.children}</Text>
    </Wrapper>
  )
}

SpeachBubble.propTypes = {
  arrowPosition: PropTypes.string
}

SpeachBubble.defaultProps = {
  arrowPosition: 'left'
}

export default SpeachBubble