import styled from 'styled-components'
import {
  breakpoint,
  Heading4Styles
} from '../../layout/mixins'
import CustomLink from '../CustomLink'
import Icon from '../Icon'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: -${({ theme }) => theme.gutter};

  ${breakpoint.up('tablet')`
    grid-template-columns: 1fr 1fr;
  `}

  ${breakpoint.up('desktop')`
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-auto-flow: column;
  `}
`

export const InfoItem = styled.div`
  margin-bottom: ${({ theme }) => theme.gutter};
`

export const InfoGroup = styled.div`
  display: flex;
  align-items: center;
`

export const InfoLink = styled(CustomLink)`
  display: inline-block;
`

export const InfoTitle = styled.span`
  ${Heading4Styles}
  margin-bottom: 0;
  margin-left: ${({ theme }) => theme.gutter};
  transition: color ${({ theme }) => theme.transitionFast};

  ${breakpoint.down('phone')`
    font-size: 1rem;
  `}

  ${InfoLink}:hover & {
    color: ${({ theme }) => theme.colorLinkhover};
  }
`

export const StyledIcon = styled(Icon)`
  && {
    transition: fill ${({ theme }) => theme.transitionFast};

    ${breakpoint.down('phone')`
      width: 2rem;
      height: 2rem;
    `}

    /* ${InfoLink}:hover & {
      fill: ${({ theme }) => theme.colorLinkhover};
    }     */
  }
`