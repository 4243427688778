import styled from 'styled-components'
import Button from '../Button'
import {
  breakpoint
} from '../../layout/mixins'
import {
  ButtonText
} from '../Button/styles'

export const ServiceButton = styled(Button)`
  position: fixed;
  display: none;
  bottom: ${({ theme }) => theme.gutter};
  right: ${({ theme }) => theme.gutter};
  background: ${({ theme }) => theme.colorWhite};
  line-height: 1;

  ${breakpoint.up('tablet')`
    display: inline-block;
  `}  

  footer.is-in-view & {
    position: absolute;
    bottom: calc(100% + ${({ theme }) => theme.gutter});
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ButtonTextPrimary = styled(ButtonText)`
  margin-bottom: 0.1rem;
`

export const ButtonTextSecondary = styled.span`
  font-size: 0.72rem;
  text-align: right;
`