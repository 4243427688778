import { fetchHeaders } from '.'

export default async function getFaqBySlug(slug) {

  const RESPONSIVE_IMAGE_FRAGMENT = `
    srcSet
    webpSrcSet
    sizes
    src
    width
    height
    aspectRatio
    alt
    title
    bgColor
    base64
  `

  const FAQ_ITEM_FRAGMENT = `
    id
    question
    answer
    slug
    createdAt
    category {
      title
    }
    image {
      responsiveImage(imgixParams: { fit: crop, w: 470, h: 758 }) {
        ${RESPONSIVE_IMAGE_FRAGMENT}
      }
    }
  `

  const QUERY = `
    faq(filter: { slug: { eq: "${slug}"  } }) {
      ${FAQ_ITEM_FRAGMENT}
    }
  `

  try {
    const response = await fetch(
      process.env.GATSBY_DATO_GRAPHQL_ENDPOINT,
      fetchHeaders(`{${QUERY}}`)
    )
    const result = await response.json()

    return result

  } catch (error) {
    console.log(error)
  }
}