import styled from 'styled-components'
import {
  HalignContent,
  breakpoint
} from '../../layout/mixins'
import InfoIcon from '../../layout/icons/info.svg'
import ArrowIcon from '../../layout/icons/arrow-long.svg'

export const Wrapper = styled.section`
  padding: ${({ theme }) => theme.gutterLarge} 0;
  position: relative;
  background: url(/images/tile.svg);
  background-size: 50%;
`

export const Inner = styled(HalignContent)`
  ${breakpoint.up('tablet')`
    display: flex;
    align-items: center;
  `}
`

export const Date = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.textcolorSecondary};
  margin-bottom: ${({ theme }) => theme.gutterSmall};
`

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${breakpoint.up('tablet')`
    padding-right: 8rem;
  `}

  ${Date} {
    display: none;

    ${breakpoint.up('tablet')`
      display: block;
    `}
  }
`

export const Header = styled.div`
  margin-bottom: ${({ theme }) => theme.gutterSmall};

  ${breakpoint.up('tablet')`
    margin-bottom: 0;    
  `}

  ${Date} {
    ${breakpoint.up('tablet')`
      display: none;
    `}
  }  
`

export const Title = styled.span`
  font-size: 1rem;
  line-height: 1.2em;
  font-weight: bold;
  color: ${({ theme }) => theme.textcolorSecondary};
  margin-bottom: ${({ theme }) => theme.gutterSmall};
  display: inline-block;
`

export const Description = styled.div`
  color: ${({ theme }) => theme.textcolorSecondary};
  font-size: 0.7rem;
  line-height: 1.1em;
  margin-bottom: ${({ theme }) => theme.gutter};

  ${breakpoint.up('tablet')`
    margin-bottom: 0;    
  `}
`

export const StyledInfoIcon = styled(InfoIcon)`
  width: 20px;
  height: 20px;
  fill: ${({ theme }) => theme.textcolorSecondary};
  vertical-align: middle;
  margin-right: ${({ theme }) => theme.gutterSmall};

  ${breakpoint.up('tablet')`
    width: 3.2rem;
    height: 3.2rem;
    margin-right: ${({ theme }) => theme.gutter};
  `}
`

export const StyledArrowIcon = styled(ArrowIcon)`
  width: 3.7rem;
  height: 1.168rem;
  fill: ${({ theme }) => theme.textcolorSecondary};

  ${breakpoint.up('tablet')`
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  `}
`