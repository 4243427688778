import styled from 'styled-components'
import {
  HalignContentStyles,
  HalignArticleStyles,
  BlockPaddingVertical,
  BlockPaddingTop,
  BlockPaddingBottom,
  HalignStyles,
  breakpoint
} from '../../layout/mixins'

export const Wrapper = styled.section`
  ${({ verticalPadding }) => verticalPadding === 'top' && BlockPaddingTop}
  ${({ verticalPadding }) => verticalPadding === 'bottom' && BlockPaddingBottom}
  ${({ verticalPadding }) => verticalPadding === 'top-bottom' && BlockPaddingVertical}
`

export const Inner = styled.div`
  ${({ horizontalPadding }) => horizontalPadding === 'full' && `
    padding-left: 0;
    padding-right: 0;
  `}

  ${({ horizontalPadding }) => horizontalPadding === 'max' && HalignStyles}

  ${({ horizontalPadding }) => horizontalPadding === 'content' && HalignContentStyles}

  ${({ horizontalPadding }) => horizontalPadding === 'article' && HalignArticleStyles}

  ${({ alignContent }) => alignContent === 'center' && `
    display: flex;
    justify-content: center;
  `}

  ${({ alignContent }) => alignContent === 'right' && `
    display: flex;
    justify-content: flex-end;
  `}

  ${breakpoint.down('phone')`
    ${({ fullMobile }) => fullMobile && `
      padding-left: 0;
      padding-right: 0;

    `}
  `}
`