import styled from 'styled-components'
import { breakpoint } from '../../layout/mixins'

export const Wrapper = styled.div`
  height: 3rem;
  line-height: 3rem;
  position: relative;
  display: inline-block;
  font-family: ${({ theme }) => theme.fontfamilyPrimary};
  font-size: 1rem;
  font-weight: 700;
  background: ${({ theme }) => theme.colorAccent};
  color: ${({ theme }) => theme.colorWhite};
  padding: ${({ theme }) => `0 calc(${theme.gutter} * 1.5)`};
  text-align: center;


  &:before {
    content: '';
    position: absolute;
    top: 100%;
    left: ${({ theme }) => theme.gutter};
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 1rem 1rem 0 0;
    border-color: ${({ theme }) => `${theme.colorAccent} transparent transparent transparent`};
    display: ${({ arrowPosition }) => arrowPosition === 'left' ? 'inline-block' : 'none'};
  }

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    right: ${({ theme }) => theme.gutter};
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 1rem 1rem 0;
    border-color: ${({ theme }) => `transparent ${theme.colorAccent} transparent transparent`};
    display: ${({ arrowPosition }) => arrowPosition === 'right' ? 'inline-block' : 'none'};
  }
`

export const Text = styled.span``