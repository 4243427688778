import styled from 'styled-components'
import {
  HalignContent,
  breakpoint,
  BlockPaddingVertical,
  Heading2,
  ParagraphStyles
} from '../../layout/mixins'
import Button from '../Button'

export const Wrapper = styled.section`
  ${BlockPaddingVertical}
  position: relative;
`

export const Inner = styled(HalignContent)``

export const Grid = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint.up('tablet')`
    flex-direction: row;
  `}  
`

export const StyledButton = styled(Button)``

export const TitleItem = styled.div`
  width: 100%;

  ${breakpoint.up('tablet')`
    padding-right: ${({ theme }) => theme.gutterXLarge};
    width: 50%;
  `}

  ${StyledButton} {
    display: none;

    ${breakpoint.up('tablet')`
      display: inline-block;
    `}    
  }
`

export const TextItem = styled.div`
  width: 100%;

  ${breakpoint.up('tablet')`
    width: 50%;
  `}

  ${StyledButton} {
    margin-top: ${({ theme }) => theme.gutter};
    
    ${breakpoint.up('tablet')`
      display: none;
    `}    
  }  
`

export const Title = styled(Heading2)`
  color: ${({ theme }) => theme.textcolorPrimary};
  margin-bottom: ${({ theme }) => theme.gutterMedium};
`

export const TextContent = styled.div`
  ${ParagraphStyles}
  color: ${({ theme }) => theme.textcolorPrimary};
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.gutter};

  ${breakpoint.up('tablet')`
    margin-bottom: 0;    
  `}
`