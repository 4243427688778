import styled from 'styled-components'
import {
  breakpoint,
  HalignStyles
} from '../../layout/mixins'
import CustomLink from '../CustomLink'

export const FooterWrapper = styled.footer`
  position: relative;
  background: ${({ theme }) => theme.colorBlack};
  color: ${({ theme }) => theme.textcolorSecondary};
  padding: ${({ theme }) => theme.gutterLarge};
`

export const FooterInner = styled.div`
  ${HalignStyles}
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  ${breakpoint.up('tablet')`
    flex-direction: row;
  `}
`

export const GridItem = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.gutterLarge};

  ${breakpoint.up('tablet')`
    margin-bottom: 0;
  `}

  &:nth-of-type(1) {
    display: none;

    ${breakpoint.up('desktop')`
      display: block;
      width: 33.33%;
      width: 15%;
    `}
  }

  &:nth-of-type(2), &:nth-of-type(3) {

    ${breakpoint.up('desktop')`
      display: block;
      width: 33.33%;
      width: 15%;
    `}
  }

  &:nth-of-type(4) {
    ${breakpoint.up('tablet')`
      width: 100%;
      margin: ${({ theme }) => `${theme.gutterLarge} 0`}
    `}

    ${breakpoint.up('desktop')`
      width: 40%;
      margin: 0;
    `}
  }

  &:nth-of-type(5) {
    ${breakpoint.up('tablet')`
      width: 100%;
    `}

    ${breakpoint.up('desktop')`
      width: 15%;
    `}
  }

  &:last-of-type {
    display: flex;
    margin-bottom: 0;

    ${breakpoint.up('desktop')`
      justify-content: flex-end;
      align-items: flex-end;
      flex-grow: 1;
    `}
  }
`

export const ColTitle = styled.span`
  display: inline-block;
  font-size: 0.8rem;
  line-height: 1.375em;
  font-weight: 700;
  margin-bottom: ${({ theme }) => `calc(${theme.gutter} / 2`});
`

export const Address = styled.div`

  p {
    font-size: 0.8rem;
    line-height: 1.423em;
  }

   ${breakpoint.up('desktop')`
      ${({ topPadding }) => topPadding && `
        margin-top: 1.9em;
      `};

      p {
        font-size: 0.7rem;
        line-height: 1.423em;
      }
   `}



`

export const Link = styled(CustomLink)`
  display: block;
  color: ${({ theme }) => theme.colorWhite};
  font-size: 0.8rem;
  line-height: 1.375em;
  font-weight: 700;
`