import styled from 'styled-components'
import {
  Heading1,
  breakpoint
} from '../../layout/mixins'
import Section from '../../components/Section/section'

export const Hero = styled(Section)`
 background: ${({ theme }) => theme.colorGrey};

  ${breakpoint.up('desktop')`
    min-height: 14.5rem;
  `}   
`

export const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Title = styled(Heading1)`
  ${breakpoint.up('tablet')`
    text-align: center;
  `}    
`

export const Filter = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${breakpoint.up('tablet')`
    flex-direction: row;
  `}  
`
export const Category = styled.li`
  position: relative;
  padding: ${({ theme }) => `0 ${theme.gutterSmall}`};
  cursor: pointer;
  margin-bottom: ${({ theme }) => theme.gutterSmall};
  font-size: 1.1rem;
  font-weight: 700;

  ${breakpoint.up('tablet')`
    font-size: 1rem;
    font-weight: 400;  
  `}  

  &:after {
    display: none;
    content: '/';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(60%, -50%);

    ${breakpoint.up('tablet')`
      display: block;
      margin-bottom: 0;
    `}
  }

  &:last-of-type:after {
    display: none;
  }

  ${({ active }) => active && `
    text-decoration: underline;
  `}
`

export const Container = styled.div`
  min-height: 42.22rem;
  text-align: center;
`