import React, { Component } from 'react'
import {
  StyledModal,
  Overlay,
  Content,
  Close
} from './styles'
import Portal from '../Portal'

class Modal extends Component {
  componentDidMount() {
    document.body.classList.add('modal-open')
  }

  componentWillUnmount() {
    document.body.classList.remove('modal-open')
  }

  render() {
    const { children, toggle } = this.props

    return (
      <Portal>
        <StyledModal>
          <Close onClick={toggle} />
          <Overlay onClick={toggle} />
          <Content>
            {children}
          </Content>
        </StyledModal>
      </Portal>
    )
  }


}

export default Modal