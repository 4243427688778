/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { graphql } from 'gatsby'
import {
  NewsCta,
  Divider
} from './styles'
import Section from '../../components/Section/section'
import FAQShortcut from '../../components/FAQShortcut'
import InformationRow from '../../components/InformationRow'
import BookServices from '../../components/BookServices'
import AreaListing from '../../components/AreaListing/areaListing'
import TextWithImage from '../../components/TextWithImage'
import NewsFeed from '../../components/NewsFeed/newsFeed'
import RentInformation from '../../components/RentInformation/rentInformation'
import Announcement from '../../components/Announcement/announcement'

const RealEstate = ({ data }) => {
  const {
    datoCmsAnnouncement: {
      announcement: [announcement]
    },
    datoCms: {
      hero: [hero],
      faq: [faq],
      realEstateListing: [realEstateListing],
      content,
      informationRow,
      bookServices
    }
  } = data

  const {
    edges: areas
  } = data.areas

  const {
    edges: allArticles
  } = data.allArticles

  const {
    rentInformation
  } = data

  return (
    <>
      <Section
        horizontalPadding="max"
        verticalPadding="none"
        fullMobile
      >
        <TextWithImage
          {...hero}
          headingSize={1}
          addShortcut={!announcement.activateAnnouncement}
          asHero
        />
      </Section>
      {announcement.activateAnnouncement && (
        <Section
          horizontalPadding="max"
          verticalPadding="none"
          fullMobile
        >
          <Announcement />
        </Section>
      )}

      { rentInformation && (
        <Section
            verticalPadding="top-bottom"
          >
            <RentInformation {...rentInformation} />
          </Section>
      )}
 

      <Section
        verticalPadding="none"
      >
        <Divider />
      </Section>

      <Section verticalPadding="top-bottom">
        <FAQShortcut {...faq} />
      </Section>

      <Section
        verticalPadding="none"
      >
        <Divider />
      </Section>

      {content.map((module, index) => (
        <Section verticalPadding="top">
          <TextWithImage
            key={`${module.title}-${index}`}
            {...module}
          />
        </Section>
      ))}

      <Section verticalPadding="top-bottom">
        <InformationRow items={informationRow} />
      </Section>

      { bookServices && (
        <BookServices {...bookServices} />
      )}


      <Section verticalPadding="top">
        <AreaListing
          {...realEstateListing}
          areas={areas.map((item) => {
            return {
              ...item.node,
              areaHero: item.node.areaHero[0],
              mapDetails: item.node.mapDetails[0]
            }
          })}
        />
      </Section>
      <Section>
        <NewsFeed articles={allArticles.map(({ node }) => node)} />
      </Section>

      <Section
        verticalPadding="bottom"
        alignContent="center"
      >
        <NewsCta
          type="link"
          text="Nyheter"
          to="/nyheter"
          icon="arrow-short"
        />
      </Section>
    </>
  )
}

export default RealEstate

export const query = graphql`
  query {
    datoCmsAnnouncement {
      announcement: notice {
        activateAnnouncement
      }
    }        
    datoCms: datoCmsRealEstate {
      hero {
        ...TextWithImageFragment
      }
      faq: faqRow {
        ...FAQShortcutFragment
      }
      informationRow {
        type: __typename
        ...InformationLinkFragment
        ...InformationTelephoneFragment
      }
      content {
        ...TextWithImageFragment
      }           
      bookServices {
        ...BookServicesRealEstateFragment
      }
      realEstateListing {
        ...AreaListingFragment
      }
      seo: seoMetaTags {
        tags
      }
    }
    rentInformation: datoCmsRentInformationRealEstate {
      ...RentInformationRealEstateFragment
    }     
    areas: allDatoCmsArea(sort: {fields: title, order: ASC}) {
      edges {
        node {
          title
          slug
          areaHero: hero {
            ...TextWithImageFragment
          }
          mapDetails {
            latitude
            longitude
          }
        }
      }
    }
    allArticles: allDatoCmsNews(limit: 4, sort: {fields: meta___createdAt, order: DESC}) {
      edges {
        node {
          title
          slug
          meta {
            createdAt
          }
          category {
            title
          }
          image {
            fluid(maxWidth: 470, sizes: "347, 694, 274, 548, 395, 478, 956") {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    } 
  }
`
