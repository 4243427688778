import styled from 'styled-components'
import { breakpoint } from '../../layout/mixins'
import CustomLink from '../CustomLink'

export const Nav = styled.nav`
  display: none;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  ${breakpoint.up('desktop')`
      display: block;
  `}
`

export const NavList = styled.ul`
  display: flex;
  align-items: center;
  height: 100%;
`

export const NavItem = styled.li`
  font-family: ${({ theme }) => theme.fontfamilyPrimary};
  font-size: 1rem;
  font-weight: 600;
  margin-right: ${({ theme }) => theme.gutter};
  cursor: pointer;
  height: 100%;

  && a {
    color: ${({ theme }) => theme.textcolorPrimary};
  }
`

export const NavLink = styled(CustomLink)`
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;

  &.active-link {
    text-decoration: none;
  }

  &:after {
    content: '';
    position: absolute;
    left: -${({ theme }) => theme.gutterSmall};
    bottom: 0;
    width: ${({ theme }) => `calc(100% + ${theme.gutter})`};
    height: 2px;
    background: ${({ theme }) => theme.colorAccent};
    transform-origin: center center;
    transition: transform ${({ theme }) => theme.transitionSlow};
    opacity: 0;
  }

  &.active-link:after {
    opacity: 1;
    animation: expandLine;
    animation-duration: 0.4s;
  }

  @keyframes expandLine {
    from {
      transform: scale(0, 1);
    }

    to {
      transform: scale(1, 1);
    }
  }
`