import styled from 'styled-components'
import { breakpoint, HalignStyles } from '../../layout/mixins'
import HamburgerIcon from '../../layout/icons/hamburger.svg'
import CloseIcon from '../../layout/icons/close.svg'
import BubbleIcon from '../../layout/icons/speaking-bubble.svg'
import ExternalIcon from '../../layout/icons/external-link.svg'
import Logo from '../Logo/logo'
import Button from '../Button'
import {
  ButtonText
} from '../Button/styles'
import CustomLink from '../CustomLink'

export const Header = styled.header`
  position: fixed;
  width: 100%;
  height: ${({ theme }) => theme.headerheightMobile};
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colorWhite};
  z-index: ${({ theme }) => theme.zLevel3};

  .menu-open & {
    background-color: transparent;
  }

  .modal-open & {
    ${breakpoint.down('tablet')`
      display: none;
    `}
  }

  ${breakpoint.up('desktop')`
    height: ${({ theme }) => theme.headerheight};
  `}
`

export const HeaderInner = styled.div`
  ${HalignStyles}
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Hamburger = styled(HamburgerIcon)`
  stroke: ${({ theme }) => theme.colorBlack};
  width: 1.5rem;
  height: 1rem;

  ${breakpoint.up('desktop')`
    display: none;
  `}

  .menu-open & {
    display: none;
  }
`

export const Close = styled(CloseIcon)`
  fill: ${({ theme }) => theme.colorWhite};
  display: none;
  width: 1.482rem;
  height: 1.626rem;

  ${breakpoint.up('desktop')`
    display: none;
  `}

  .menu-open & {
    display: block;

    ${breakpoint.up('desktop')`
      display: none;
    `}
  }
`

export const StyledLogo = styled(Logo)`
  .menu-open & {
    display: none;

    ${breakpoint.up('desktop')`
      display: block;
    `}
  }
`

export const ButtonGroup = styled.div`
  display: none;
  height: 100%;

  ${breakpoint.up('desktop')`
    display: flex;
  `}
`

export const FAQ = styled(CustomLink)`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 ${({ theme }) => theme.gutter};
  font-size: 1rem;
  font-weight: 600;
  margin-right: ${({ theme }) => theme.gutter};
  background: ${({ theme }) => theme.colorAccent};
  color: ${({ theme }) => theme.colorWhite};
`

export const SpeakingBubble = styled(BubbleIcon)`
  width: 1em;
  height: 1em;
  display: block;
  fill: ${({ theme }) => theme.colorWhite};
  margin-left: ${({ theme }) => theme.gutter};
  transform: translateY(0.1em);
`

export const MyPages = styled(CustomLink)`
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
`

export const ExternalLinkIcon = styled(ExternalIcon)`
  width: 1em;
  height: 1em;
  display: block;
  fill: ${({ theme }) => theme.colorBlack};
  margin-left: ${({ theme }) => theme.gutter};
`