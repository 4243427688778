import { createGlobalStyle } from 'styled-components'
import GreycliffCFRegular from './greycliffcf-regular.otf'
import GreycliffCFDemibold from './greycliffcf-demibold.otf'
import GreycliffCFBold from './greycliffcf-bold.otf'

const Typography = createGlobalStyle`
  @font-face {
    font-family: "Greycliff CF";
    src: url(${GreycliffCFRegular}) format("opentype");
    font-weight: 400;
  }

  @font-face {
    font-family: "Greycliff CF";
    src: url(${GreycliffCFDemibold}) format("opentype");
    font-weight: 600;
  }

  @font-face {
    font-family: "Greycliff CF";
    src: url(${GreycliffCFBold}) format("opentype");
    font-weight: 700;
  }
`
export default Typography