import React from 'react'
import PropTypes from 'prop-types'
import {
  Wrapper,
  Inner
} from './styles'

const Section = ({
  horizontalPadding,
  verticalPadding,
  alignContent,
  fullMobile,
  children,
  className
}) => {
  return (
    <Wrapper
      verticalPadding={verticalPadding}
      className={className + ' section'}
    >
      <Inner
        alignContent={alignContent}
        horizontalPadding={horizontalPadding}
        fullMobile={fullMobile}
      >
        {children}
      </Inner>
    </Wrapper>
  )
}

export default Section

Section.propTypes = {
  horizontalPadding: PropTypes.oneOf(['full', 'max', 'content', 'article']),
  verticalPadding: PropTypes.oneOf(['none', 'top', 'bottom', 'top-bottom']),
  alignContent: PropTypes.oneOf(['left', 'center', 'right']),
  fullMobile: PropTypes.bool
}

Section.defaultProps = {
  horizontalPadding: 'content',
  verticalPadding: 'top-bottom',
  alignContent: 'left',
  fullMobile: false
}