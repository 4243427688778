import styled from 'styled-components'
import Section from '../../components/Section/section'
import {
  BlockPaddingBottom
} from '../../layout/mixins'

export const AboutSection = styled(Section)`
  &:last-of-type {
    ${BlockPaddingBottom}
  }
`