import styled from 'styled-components'
import {
  breakpoint,
  ParagraphStyles,
  truncateOverflow,
  Heading3Styles,
  Heading4Styles
} from '../../layout/mixins'
import ExternalIcon from '../../layout/icons/external-link.svg'
import CustomLink from '../CustomLink'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${({ theme }) => theme.gutter};
  grid-auto-rows: 1fr
  
  ${breakpoint.up('tablet')`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${breakpoint.up('large')`
    grid-template-columns: repeat(3, 1fr);
  `}
`

export const TextContent = styled.div`
  ${ParagraphStyles}
  color: ${({ theme }) => theme.textcolorPrimary};
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.gutterMedium};
`

export const InfoItem = styled.div`

`

export const LinkCard = styled(CustomLink)`
  position: relative;
  display: block;
  height: 100%;
  border-radius: ${({ theme }) => theme.buttonRadius};
  padding: ${({ theme }) => theme.gutterLarge};
  padding-right: ${({ theme }) => theme.gutterXXLarge};
  transition: background ${({ theme }) => theme.transitionRegular};

  &:hover {
    background: ${({ theme }) => theme.colorBlack};
  }

  ${InfoItem}:nth-of-type(3n + 1) & {
    border: 2px solid ${({ theme }) => theme.colorYellow};
  }

  ${InfoItem}:nth-of-type(3n + 2) & {
    border: 2px solid ${({ theme }) => theme.colorAccent};
  }

  ${InfoItem}:nth-of-type(3n + 3) & {
    border: 2px solid ${({ theme }) => theme.colorBlack};
  }

  ${InfoItem}:nth-of-type(3n + 1) &:hover {
    background: ${({ theme }) => theme.colorYellow};
  }

  ${InfoItem}:nth-of-type(3n + 2) &:hover {
    background: ${({ theme }) => theme.colorAccent};
  }

  ${InfoItem}:nth-of-type(3n + 3) &:hover {
    background: ${({ theme }) => theme.colorBlack};
  }
`

export const TelephoneCard = styled.div`
  position: relative;
  display: block;
  height: 100%;
  border-radius: ${({ theme }) => theme.buttonRadius};
  padding: ${({ theme }) => theme.gutterLarge};
  padding-right: ${({ theme }) => theme.gutterXXLarge};
  transition: background ${({ theme }) => theme.transitionRegular};

  &:hover {
    background: ${({ theme }) => theme.colorBlack};
  }

  ${InfoItem}:nth-of-type(3n + 1) & {
    border: 2px solid ${({ theme }) => theme.colorYellow};
  }

  ${InfoItem}:nth-of-type(3n + 2) & {
    border: 2px solid ${({ theme }) => theme.colorAccent};
  }

  ${InfoItem}:nth-of-type(3n + 3) & {
    border: 2px solid ${({ theme }) => theme.colorBlack};
  }

  ${InfoItem}:nth-of-type(3n + 1) &:hover {
    background: ${({ theme }) => theme.colorYellow};
  }

  ${InfoItem}:nth-of-type(3n + 2) &:hover {
    background: ${({ theme }) => theme.colorAccent};
  }

  ${InfoItem}:nth-of-type(3n + 3) &:hover {
    background: ${({ theme }) => theme.colorBlack};
  }
`

export const InfoTitle = styled.span`
  display: block;
  ${Heading3Styles}
  margin-bottom: ${({ theme }) => theme.gutterSmall};
  transition: color ${({ theme }) => theme.transitionRegular};

  ${LinkCard}:hover &, ${TelephoneCard}:hover & {
    color: ${({ theme }) => theme.colorWhite};
  }
`

export const InfoText = styled.span`
  ${truncateOverflow(4)}
  ${ParagraphStyles}
  font-size: 0.78rem;
  transition: color ${({ theme }) => theme.transitionRegular};

  ${LinkCard}:hover &, ${TelephoneCard}:hover & {
    color: ${({ theme }) => theme.colorWhite};
  }
`

export const ExternalLink = styled(ExternalIcon)`
  position: absolute;
  top: ${({ theme }) => theme.gutterMedium};
  right: ${({ theme }) => theme.gutterMedium};
  width: 0.833rem;
  height: 0.833rem;
  fill: ${({ theme }) => theme.colorBlack};
  transition: fill ${({ theme }) => theme.transitionRegular};

  ${LinkCard}:hover &, ${TelephoneCard}:hover & {
    fill: ${({ theme }) => theme.colorWhite};
  }
`

export const Subtitle = styled.span`
  font-size: 0.78rem;

  transition: color ${({ theme }) => theme.transitionRegular};

  ${LinkCard}:hover &, ${TelephoneCard}:hover & {
    color: ${({ theme }) => theme.colorWhite};
  }
`

export const PhoneNumber = styled.a`
  ${Heading4Styles}
  margin-top: 16px;
  width: fit-content;
  margin-bottom: 0;
  display: block;
  transition: color ${({ theme }) => theme.transitionRegular};

  ${LinkCard}:hover &, ${TelephoneCard}:hover & {
    color: ${({ theme }) => theme.colorWhite};
  }
  &:last-child {
    margin-bottom: ${({ theme }) => theme.gutter};
  }
`
export const Link = styled(CustomLink)`
  ${Heading4Styles}
  margin-top: 16px;
  width: fit-content;
  margin-bottom: 0;
  display: block;
  transition: color ${({ theme }) => theme.transitionRegular};

  ${LinkCard}:hover &, ${TelephoneCard}:hover & {
    color: ${({ theme }) => theme.colorWhite};
  }
  &:last-child {
    margin-bottom: ${({ theme }) => theme.gutter};
  }
`