import React from 'react'
import PropTypes from 'prop-types'
import Image from 'gatsby-image'
import { protectEmail, protectEmailAlways, protectEmailFactory } from 'protect-email'

import {
  Container,
  Heading,
  Content,
  Phone,
  Title,
  Email,
  Information
} from './styles'

const EmployeeCard = ({
  image, name, title, email, phone, additionalInformation
}) => {
  const sizes = '191px'

  const fluidImage = {
    ...image.fluid,
    sizes
  }

  return (
    <Container>
      <Image
        fluid={fluidImage}
      />
      <Content>
        <Heading>{name}</Heading>
        <p>
          <Title>{title}</Title>
          <br />
          {phone !== '' && (
          <>
            <Phone href={`tel:${phone}`}>{phone}</Phone>
            <br />
          </>
          )}
          {additionalInformation !== '' && (
          <>
            <Information>{additionalInformation}</Information>
            <br />
          </>
          )}
          {email !== '' && (
          <>
            <Email dangerouslySetInnerHTML={{ __html: protectEmail(email) }} />
            <br />
          </>
          )}
        </p>
      </Content>
    </Container>
  )
}

export default EmployeeCard