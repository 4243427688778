/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { graphql } from 'gatsby'
import { formatDate } from '../../utils/helpers'
import {
  Article,
  Divider,
  Pagination,
  PaginationTitle,
  Prev,
  Next,
  ArrowLeft,
  ArrowLeftMobile,
  ArrowRight
} from './styles'
import Section from '../../components/Section/section'
import SingleNewsHero from '../../components/SingleNewsHero'

const SingleNewsTemplate = ({ data, pageContext }) => {
  const {
    title,
    textContent,
    preamble,
    image,
    category,
    meta
  } = data.NewsItem

  const {
    nextCase,
    prevCase
  } = pageContext

  return (
    <>
      <SingleNewsHero
        title={title}
        category={category[0].title}
        date={formatDate(meta.createdAt)}
        text={preamble}
        image={image}
        headingSize={3}
        addShortcut
        asHero
      />

      <Section
        verticalPadding="top-bottom"
        horizontalPadding="article"
      >
        <Article
          className="rte"
          dangerouslySetInnerHTML={{ __html: textContent }}
        />
        <Divider />
        <Pagination>
          <Prev
            to={`/nyheter/${prevCase.slug}`}
          >
            <ArrowLeft />
            <PaginationTitle>{prevCase.title}</PaginationTitle>
            <ArrowLeftMobile />
          </Prev>
          <Next
            to={`/nyheter/${nextCase.slug}`}
          >
            <PaginationTitle>{nextCase.title}</PaginationTitle>
            <ArrowRight />
          </Next>
        </Pagination>
      </Section>
    </>
  )
}

export default SingleNewsTemplate

export const query = graphql`
  query SingleNewsTemplate($id: String) {
    NewsItem: datoCmsNews(id: {eq: $id}) {
      textContent
      title
      preamble
      image {
        fluid(maxWidth: 2048, sizes: "375, 750, 850, 1024, 1700, 2048") {
          ...GatsbyDatoCmsFluid
        }
      }
      meta {
        createdAt
      }
      category {
        title
        meta {
          createdAt
        }
      }
      seo: seoMetaTags {
        tags
      } 
    }
  }
`