import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
  Wrapper,
  Inner,
  Header,
  Content,
  Date,
  Title,
  Description,
  StyledInfoIcon,
  StyledArrowIcon
} from './styles'

const Announcement = () => {
  const {
    announcements: [announcement]
  } = useStaticQuery(AnnouncementQuery).announcement

  const {
    date,
    title,
    textContent,
    addLink,
    linkUrl
  } = announcement

  return (
    <Wrapper>
      <Inner>
        <Header>
          <StyledInfoIcon />
          <Date>{date}</Date>
        </Header>
        { addLink ? (
        <Content>
          <a href={linkUrl}>
            <Date>{date}</Date>
            <Title>{title}</Title>
            <Description>{textContent}</Description>
            <StyledArrowIcon />
          </a>
        </Content>
        ) : (
        <Content>
          <Date>{date}</Date>
          <Title>{title}</Title>
          <Description>{textContent}</Description>
          <StyledArrowIcon />
        </Content>
        )}

      </Inner>
    </Wrapper>
  )
}

export default Announcement

export const AnnouncementQuery = graphql`
  query AnnouncementQuery {
    announcement: datoCmsAnnouncement {
       announcements: notice {
        date
        title
        textContent
        addLink
        linkUrl
      }
    }
  }

`