export default function headers(query = '') {
  const token = process.env.GATSBY_DATO_API_TOKEN

  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({
      query
    }),
  }
}