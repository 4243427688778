import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import {
  Grid,
  TextItem,
  IconsItem,
  Title,
  TextContent,
  InfoList,
  InfoItem,
  InfoLink,
  InfoGroup,
  InfoTitle,
  StyledIcon
} from './styles'

const RentInformation = ({
  title,
  textContent,
  links
}) => {
  return (
    <Grid>
      <TextItem>
        <Title>{title}</Title>
        <TextContent
          className="rte"
          dangerouslySetInnerHTML={{ __html: textContent }}
        />
      </TextItem>
      <IconsItem>
        <InfoList>
          {links && links.map((item) => {
            return (
            <InfoItem>
              {item.__typename === 'DatoCmsLink' && (
                <InfoLink to={item.url}>
                  <InfoGroup>
                    {item.icon && <StyledIcon name={JSON.parse(item.icon)[0]} />}
                    <InfoTitle>{item.title}</InfoTitle>
                  </InfoGroup>
                </InfoLink>
              )}

              {item.__typename === 'DatoCmsFile' && (
                <InfoLink to={item?.file?.url} target="_blank">
                  <InfoGroup>
                    {item.icon && <StyledIcon name={JSON.parse(item.icon)[0]} />}
                    <InfoTitle>{item.title}</InfoTitle>
                  </InfoGroup>
                </InfoLink>
              )}
            </InfoItem>
          )})}
        </InfoList>
      </IconsItem>
    </Grid>
  )
}

export default RentInformation

RentInformation.propTypes = {
  title: PropTypes.string.isRequired,
  textContent: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    icon: PropTypes.string
  })).isRequired
}

RentInformation.defaultProps = {
  textContent: 'This is the text content'
}

export const RentInformationResidenceQuery = graphql`
  fragment RentInformationResidenceFragment on DatoCmsRentInformationResidence {
    title
    textContent
    links {
        __typename
        ... on DatoCmsLink {
          id
          url
          title
          icon
        }
        ... on DatoCmsFile {
          id
          icon
          title
          file {
            url
          }
        }         
      }
  }
`

export const RentInformationRealEstateQuery = graphql`
  fragment RentInformationRealEstateFragment on DatoCmsRentInformationRealEstate {
    title
    textContent
    links {
        __typename
        ... on DatoCmsLink {
          id
          url
          title
          icon
        }
        ... on DatoCmsFile {
          id
          icon
          title
          file {
            url
          }
        }        
      }
  }
`