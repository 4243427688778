/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { graphql } from 'gatsby'
import {
  ConnectionsTitle,
  ConnectionTime,
  AddressesTitle,
  Addresses,
  AreaInfo,
  TravelIcon
} from './styles'
import Section from '../../components/Section/section'
import TextWithImage from '../../components/TextWithImage'
import AreaMap from '../../components/AreaMap/areaMap'

const SingleAreaTemplate = ({ data }) => {
  const {
    hero: [hero],
    content,
    mapDetails,
    getToArea: [getToArea = {}]
  } = data.Area

  return (
    <>
      <Section
        horizontalPadding="max"
        verticalPadding="none"
        fullMobile
      >
        <TextWithImage
          {...hero}
          headingSize={1}
          addShortcut
          asHero
        />
      </Section>
      <Section
        verticalPadding="top"
        fullMobile
      >
        <TextWithImage
          title={getToArea.title}
          text={getToArea.description}
          image={getToArea.image}
          headingSize={2}
          alignRight
        >
          <AreaInfo>
            <ConnectionsTitle>{getToArea.travelTimeTitle}</ConnectionsTitle>
            <ConnectionTime>
              <TravelIcon name="public-transportation" />
              {getToArea.travelTimeCar}
            </ConnectionTime>
            <ConnectionTime>
              <TravelIcon name="car" />
              {getToArea.travelTimePublic}
            </ConnectionTime>
            <ConnectionTime>
              <TravelIcon name="bike" />
              {getToArea.restidCykel}
            </ConnectionTime>

            <AddressesTitle>{getToArea.titleAddresses}</AddressesTitle>
            <Addresses
              className="rte"
              dangerouslySetInnerHTML={{ __html: getToArea.addresses }}
            />
          </AreaInfo>
        </TextWithImage>
      </Section>
      {content.map((module, index) => (
        <Section
          className="about-section"
          verticalPadding="top"
          fullMobile
        >
          <TextWithImage
            key={`${module.title}-${index}`}
            {...module}
          />
        </Section>
      ))}
      <Section verticalPadding="top-bottom">
        <AreaMap
          areas={[
            {
              ...data.Area,
              areaHero: hero,
              mapDetails: mapDetails[0]
            }
          ]}
        />
      </Section>
    </>
  )
}

export default SingleAreaTemplate

export const query = graphql`
  query SingleAreaTemplate($id: String) {
    Area: datoCmsArea(id: {eq: $id}) {
      title
      slug
      hero {
        ...TextWithImageFragment
      }
      getToArea {
        title
        description
        image {
          fluid(maxWidth: 2048, sizes: "375, 750, 850, 1024, 1700, 2048") {
            ...GatsbyDatoCmsFluid
          }
        }
        addresses
        titleAddresses
        travelTimeCar
        travelTimePublic
        travelTimeTitle
        restidCykel
      }
      content {
        ...TextWithImageFragment
      }      
      mapDetails {
        latitude
        longitude
      }
      seo: seoMetaTags {
        tags
      } 
    }
  }
`