import styled from 'styled-components'
import LinkIcon from '../../layout/icons/external-link.svg'
import {
  breakpoint
} from '../../layout/mixins'

export const Wrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: ${({ theme }) => theme.gutterSmall};

  ${breakpoint.up('tablet')`
    padding: ${({ theme }) => theme.gutter};
  `}
`

export const StyledLinkIcon = styled(LinkIcon)`
  width: 1rem;
  height: 1rem;
  fill: ${({ theme }) => theme.textcolorSecondary};

  ${breakpoint.up('tablet')`
    width: 1.6rem;
    height: 1.6rem;     
  `}
`