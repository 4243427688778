import { fetchHeaders } from '.'

export default async function getFaqsByTag(tagId, first, skip) {
  const RESPONSIVE_IMAGE_FRAGMENT = `
    srcSet
    webpSrcSet
    sizes
    src
    width
    height
    aspectRatio
    alt
    title
    bgColor
    base64
  `

  const FAQ_ITEM_FRAGMENT = `
    id
    question
    answer
    slug
    createdAt
    category {
      title
    }
    image {
      responsiveImage(imgixParams: { fit: crop, w: 470, h: 758 }) {
        ${RESPONSIVE_IMAGE_FRAGMENT}
      }
    }
  `

  const PAGINATION_FRAGMENT = `first: ${first}, skip: ${skip}`
  const SORT_FRAGMENT = 'orderBy: _createdAt_DESC'

  const FILTER_QUERY = `
    allFaqs(${SORT_FRAGMENT}, ${PAGINATION_FRAGMENT}, filter: { category: { in: ${tagId} } }) {
      ${FAQ_ITEM_FRAGMENT}
    }
    _allFaqsMeta(filter: { category: { in: ${tagId} } }) {
      count
    }
  `

  const NO_FILTER_QUERY = `
    allFaqs(${SORT_FRAGMENT}, ${PAGINATION_FRAGMENT}) {
      ${FAQ_ITEM_FRAGMENT}
    }
    _allFaqsMeta {
      count
    }    
  `

  const QUERY = tagId === 'all' ? NO_FILTER_QUERY : FILTER_QUERY
  try {
    const response = await fetch(
      process.env.GATSBY_DATO_GRAPHQL_ENDPOINT,
      fetchHeaders(`{${QUERY}}`)
    )
    const result = await response.json()

    const modifiedItems = result.data.allFaqs.map((item) => {
      return {
        ...item,
        meta: {
          createdAt: item.createdAt
        }
      }
    })

    return {
      data: {
        allFaqs: modifiedItems,
        count: result.data._allFaqsMeta.count
      }
    }
  } catch (error) {
    console.log(error)
  }
}