import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import {
  Grid,
  InfoItem,
  InfoLink,
  InfoGroup,
  InfoTitle,
  StyledIcon
} from './styles'

const FAQLinks = ({
  links
}) => {
  return (
    <Grid>
      {links && links.map((item) => (
        <InfoItem key={item.id}>

          {item.__typename === 'DatoCmsLink' && (
            <InfoLink to={item.url}>
              <InfoGroup>
                {item.icon && <StyledIcon name={JSON.parse(item.icon)[0]} />}
                <InfoTitle>{item.title}</InfoTitle>
              </InfoGroup>
            </InfoLink>
          )}

          {item.__typename === 'DatoCmsFile' && (
            <InfoLink to={item.file.url} target="_blank">
              <InfoGroup>
                {item.icon && <StyledIcon name={JSON.parse(item.icon)[0]} />}
                <InfoTitle>{item.title}</InfoTitle>
              </InfoGroup>
            </InfoLink>
          )}
        </InfoItem>
      ))}
    </Grid>
  )
}

export default FAQLinks

FAQLinks.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string])
  })).isRequired
}

export const FAQLinksResidenceQuery = graphql`
  fragment FAQLinksFragment on DatoCmsLinksFaq {
    links {
      __typename
      ... on DatoCmsLink {
        id
        url
        title
        icon
      }
      ... on DatoCmsFile {
        id
        icon
        title
        file {
          url
        }
      }      
    }
  }
`