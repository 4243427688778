import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import {
  Grid,
  ListingItem,
  Title,
  Description,
  AreaList,
  AreaListItem,
  AreaLink,
  MapItem
} from './styles'
import AreaMap from '../AreaMap/areaMap'

const AreaListing = ({
  title,
  textContent,
  areas
}) => {

  return (
    <Grid>
      <ListingItem>
        <Title>{title}</Title>
        <Description
          className="rte"
          dangerouslySetInnerHTML={{ __html: textContent }}
        />
        <AreaList>
          {areas.map((area) => (
            <AreaListItem>
              <AreaLink to={`/omraden/${area.slug}`}>
                {area.areaHero.title}
              </AreaLink>
            </AreaListItem>
          ))}
        </AreaList>
      </ListingItem>
      <MapItem>
        <AreaMap areas={areas} />
      </MapItem>
    </Grid>
  )
}

export default AreaListing

AreaListing.propTypes = {
  title: PropTypes.string.isRequired,
  textContent: PropTypes.string.isRequired,
  areas: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string.isRequired,
    areaHero: PropTypes.shape({
      title: PropTypes.string.isRequired,
      textContent: PropTypes.string.isRequired,
      backgroundImage: PropTypes.shape({
        fluid: PropTypes.shape({
          aspectRatio: PropTypes.number.isRequired,
          base64: PropTypes.string.isRequired,
          sizes: PropTypes.string.isRequired,
          src: PropTypes.string.isRequired,
          srcSet: PropTypes.string.isRequired
        }).isRequired
      }).isRequired,
      addPrimaryLink: PropTypes.bool,
      primaryLinkTitle: PropTypes.string,
      primaryLinkUrl: PropTypes.string,
      addSecondaryLink: PropTypes.bool,
      secondaryLinkTitle: PropTypes.string,
      secondaryLinkUrl: PropTypes.string
    }),
    mapDetails: PropTypes.shape({
      latitude: PropTypes.string.isRequired,
      longitude: PropTypes.string.isRequired
    })
  })).isRequired
}

export const AreaListingQuery = graphql`
  fragment AreaListingFragment on DatoCmsRealEstateListing {
    title
    textContent
  }
`