import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import { graphql, useStaticQuery } from 'gatsby'
import {
  FooterWrapper,
  FooterInner,
  GridItem,
  ColTitle,
  Address,
  Link
} from './styles'
import Logo from '../Logo/logo'
import ServiceNotification from '../ServiceNotification/serviceNotification'

const Footer = () => {
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0,
  })

  const {
    datoCmsFooter: { links, textColumn1, textColumn2 }
  } = useStaticQuery(FooterQuery)


  return (
    <FooterWrapper
      className="footer"
      className={inView ? 'is-in-view' : ''}
      ref={ref}
    >
      <FooterInner>
        <GridItem>
          {links.map(({ url, title }) => <Link key={url} to={url}>{title}</Link>)}
        </GridItem>
        <GridItem>
          <ColTitle>Källfelt Byggnads AB</ColTitle>
          <Address dangerouslySetInnerHTML={{ __html: textColumn1 }} />
        </GridItem>
        <GridItem>
          <Address topPadding dangerouslySetInnerHTML={{ __html: textColumn2 }} />
        </GridItem>
        <GridItem>
          <Logo type="small" />
        </GridItem>
      </FooterInner>
      <ServiceNotification />
    </FooterWrapper>
  )
}

export default Footer

export const FooterQuery = graphql`
  query FooterQuery {
    datoCmsFooter {
      textColumn1
      textColumn2
      links {
        title
        url
      }
    }
  }
`