import styled from 'styled-components'
import { breakpoint } from '../../layout/mixins'
import { Wrapper as ButtonStyles } from '../Button/styles'

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  z-index: ${({ theme }) => theme.zLevel2};
  bottom: ${({ theme }) => theme.gutter};
  padding: 0 ${({ theme }) => theme.gutter};
  
  .CookieConsent {
    background: ${({ theme }) => theme.colorGrey};
    padding: ${({ theme }) => theme.gutter};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${({ theme }) => theme.colorBlack};

    ${breakpoint.up('tablet')`
      flex-direction: row;
    `}
  }

  .cookieConsentContent {
    font-size: 1rem;
    line-height: 1.1em;
    margin-bottom: ${({ theme }) => theme.gutter};
    text-align: center;
    width: 70%;

    ${breakpoint.up('tablet')`
      margin-bottom: 0;
      text-align: left;
    `}
  }

  .cookieConsentContent a {
    text-decoration: underline;
  }

  .cookieConsentButton {
    ${ButtonStyles}
    line-height: 1;

    &:hover {
      background: ${({ theme }) => theme.colorBlack};
      color: ${({ theme }) => theme.colorWhite};
    }

  }
`