/* eslint-disable react/jsx-indent */
import React from 'react'
import {
  Nav,
  NavList,
  NavItem,
  NavLink
} from './styles'

const Menu = ({ menuItems }) => {
  return (
    <Nav>
      <NavList>
        {menuItems.map(({ link }) => {
          return(
            <NavItem
              key={link.title}
              onClick={() => {
                document
                  .querySelector('body')
                  .classList
                  .remove('menu-open')
              }}
            >
              <NavLink to={link.slug} activeClassName="active-link">
                {link.title}
              </NavLink>
            </NavItem>
        )})}
      </NavList>
    </Nav>
  )
}

Menu.defaultProps = {
  menuItems: [
    {
      link: {
        title: 'Bostad',
        slug: 'bostad'
      }
    },
    {
      link: {
        title: 'Lokal',
        slug: 'lokal'
      }
    },
    {
      link: {
        title: 'Om oss',
        slug: 'om-oss'
      }
    },
    {
      link: {
        title: 'Publikationer',
        slug: '/publikationer'
      }
    },
    {
      link: {
        title: 'Kontakt',
        slug: 'kallfelt-byggnads-ab'
      }
    }
  ]
}

export default Menu
