import React from 'react'
import { graphql } from 'gatsby'
import {
  NewsCta
} from './styles'
import StartHero from '../../components/StartHero/start-hero'
import Announcement from '../../components/Announcement/announcement'
import TwoColText from '../../components/TwoColText/two-col-text'
import BlockLinks from '../../components/BlockLinks/blockLinks'
import AreaMap from '../../components/AreaMap/areaMap'
import AreaListing from '../../components/AreaListing/areaListing'
import Section from '../../components/Section/section'
import NewsFeed from '../../components/NewsFeed/newsFeed'

const Home = ({ data }) => {
  const {
    heroModules: [hero],
    textContentModules: [textContent],
    rentLinks,
    residenceListing: [residenceListing]
  } = data.frontPage

  const {
    announcement: [announcement]
  } = data.datoCmsAnnouncement

  const {
    edges: areas
  } = data.areas

  const {
    edges: allArticles
  } = data.allArticles


  return (
    <>
      <StartHero {...hero} />
      {announcement.activateAnnouncement && (
        <Announcement />
      )}
      <TwoColText {...textContent} />
      <BlockLinks {...rentLinks} />
      <Section verticalPadding="top">
        <AreaListing
          {...residenceListing}
          areas={areas.map((item) => {
            return {
              ...item.node,
              areaHero: item.node.areaHero[0],
              mapDetails: item.node.mapDetails[0]
            }
          })}
        />
      </Section>
      <Section>
        <NewsFeed articles={allArticles.map(({ node }) => node)} />
      </Section>
      <Section
        verticalPadding="bottom"
        alignContent="center"
      >
        <NewsCta
          type="link"
          text="Nyheter"
          to="/nyheter"
          icon="arrow-short"
        />
      </Section>
    </>
  )
}

export default Home

export const query = graphql`
  query {
    datoCmsAnnouncement {
      announcement: notice {
        activateAnnouncement
      }
    }   
    frontPage: datoCmsFrontpage {
      heroModules: hero {
        ...HeroFragment
      }
      textContentModules: textContent {
        ...TwoColTextFragment
      }
      rentLinks {
        ...BlockLinksFragment
      }
      seo: seoMetaTags {
        tags
      }
      residenceListing {
        ...AreaListingFragment
      }
    }
    areas: allDatoCmsArea(sort: {fields: title, order: ASC}) {
      edges {
        node {
          title
          slug
          areaHero: hero {
            ...TextWithImageFragment
          }
          mapDetails {
            latitude
            longitude
          }
        }
      }
    }
    allArticles: allDatoCmsNews(limit: 4, sort: {fields: meta___createdAt, order: DESC}) {
      edges {
        node {
          title
          slug
          meta {
            createdAt
          }
          category {
            title
          }
          image {
            fluid(maxWidth: 470, sizes: "347, 694, 274, 548, 395, 478, 956") {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`