import styled from 'styled-components'
import {
  Heading2,
  ParagraphStyles,
  breakpoint
} from '../../layout/mixins'
import CustomLink from '../CustomLink'

export const Grid = styled.div`
  display: flex;
  width: 100%;
`

export const ListingItem = styled.div`
  width: 100%;

  ${breakpoint.up('tablet')`
    padding-right: ${({ theme }) => theme.blockpadding};
    width: 50%;
  `}
`

export const MapItem = styled.div`
  width: 50%;
  display: none;

  ${breakpoint.up('tablet')`
    display: block;
  `}
`

export const Title = styled(Heading2)``

export const Description = styled.div`
  ${ParagraphStyles}
  margin-bottom: ${({ theme }) => theme.gutter};
`

export const AreaList = styled.ul`
  list-style: none;
  padding-left: 0;
  
`

export const AreaListItem = styled.li`
  font-weight: bold;
  padding: ${({ theme }) => `${theme.gutterSmall} 0`};
  border-bottom: ${({ theme }) => `1px solid ${theme.colorHr}`};
`

export const AreaLink = styled(CustomLink)`
  transition: color ${({ theme }) => theme.transitionFast};

  &:hover {
    color: ${({ theme }) => theme.colorLinkhover};
  }
`