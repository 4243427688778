import styled from 'styled-components'
import { breakpoint, Halign } from '../../layout/mixins'
import ArrowIcon from '../../layout/icons/arrow-short.svg'
import PinIcon from '../../layout/icons/pin.svg'

export const Wrapper = styled.blockquote``

export const Text = styled.p`
  position: relative;

  &:before {
    content: '“';
    padding-right: 2px; 
  }
  &:after {
    content: '”';
    padding-left: 2px;
  }
`

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  padding-top: ${({ theme }) => theme.gutter};
  font-size: ${({ theme }) => theme.fontsizeSmall};
`

export const Arrow = styled(ArrowIcon)`
  width: ${({ theme }) => theme.buttonIconSize};
  height: ${({ theme }) => theme.buttonIconSize};
  fill: ${({ theme }) => theme.colorBlack};
  margin-right: ${({ theme }) => theme.gutterSmall};
`
export const Pin = styled(PinIcon)`
  fill: ${({ theme }) => theme.colorAccent};
`

export const FooterText = styled.p``