import React from 'react'
import { createGlobalStyle } from 'styled-components'
import {
  breakpoint,
  ParagraphStyles,
  Heading1Styles,
  Heading2Styles,
  Heading3Styles,
  Heading4Styles
} from './mixins'
import theme from './theme'

const GlobalStyles = createGlobalStyle`
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    background: #fff;
  }

  html {
    font-family: ${theme.fontfamilyPrimary};
    font-size: ${theme.fontsizeMobile};

    ${breakpoint.up('tablet')`
      font-size: ${theme.fontsizeResponsive};
    `}

    ${breakpoint.up('max')`
      font-size: ${theme.fontsizeRoot};
    `}
  }

  #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
  }

  // /* for sticky footer */

  .site-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .content {
    flex: 1 0 auto;
  }

  .service-column {
    position: fixed;
    width: 200px;
    height: 100vh;
    right: 0;
    top: 0;
    background: deeppink;
  }

  .footer {
    flex-shrink: 0;
    flex-grow: 0;
  }

  /* end for sticky footer */
  
  body.menu-open {
    overflow: hidden; 

    ${breakpoint.up('desktop')`
      overflow: auto;
    `}
  }

  body.modal-open {
    overflow: hidden; 
  }

  #modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: ${theme.zLevel4};
    pointer-events: none;

    .modal-open & {
      pointer-events: auto;
    }
  }

  main {
    margin-top: ${theme.headerheightMobile};

    ${breakpoint.up('desktop')`
      margin-top: ${theme.headerheight};
    `}
  }

  a {
    text-decoration: none;
    color: ${theme.textcolorPrimary};
  }

  a.active-link {
    text-decoration: underline;
  }

  ul {
    padding-left: 0;
  }

  h1 {
    ${Heading1Styles}
  }

  h2 {
    ${Heading2Styles}
  }

  h3 {
    ${Heading3Styles}
  }

  h4 {
    ${Heading4Styles}
  }

  p {
    ${ParagraphStyles}
  }

  hidden {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  }
`
export default GlobalStyles