import styled from 'styled-components'
import Img from 'gatsby-image'
import { Image } from 'react-datocms'
import CustomLink from '../../components/CustomLink'

import {
  NewsTextSmallStyles,
  NewsTextLargeStyles,
  breakpoint,
  truncateOverflow,
  hyphens
} from '../../layout/mixins'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 20.56rem;
  grid-gap: ${({ theme }) => theme.gutter};

  ${breakpoint.up('tablet')`
    grid-template-columns: 1fr 1fr;
  `}

  ${breakpoint.up('large')`
    grid-template-columns: 1fr 1fr 1fr;
  `}
`

export const FeedItem = styled(CustomLink)`
  background: ${({ theme }) => theme.colorGrey};
  position: relative;
  overflow: hidden;
  backface-visibility: hidden;

  &:nth-of-type(1), &:nth-of-type(2) {
    ${breakpoint.up('large')`
      grid-row: span 2;
    `}

    :not(.has-image) {
      background: url(/images/tile.svg);
      background-size: 100%;
    }
  }
`

export const ItemImage = styled(Img)`
  height: 100%;
  width: 100%;
  transition: transform ${({ theme }) => theme.transitionRegular};
  transform-origin: center;

  ${FeedItem}:hover & {
    transform: scale3d(1.05, 1.05, 1);
  }
`

export const DatoItemImage = styled(Image)`
  position: relative;
  height: 100%;
  width: 100%;

  img {
    postion: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }
`

export const Gradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(30, 31, 29, 0) 68.58%, #1E1F1D 100%);
`

export const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.gutter};
`

export const Title = styled.span`
  position: absolute;
  width: 75%;
  bottom: ${({ theme }) => theme.gutter};
  left: ${({ theme }) => theme.gutter};
  ${NewsTextSmallStyles}
  text-align: left;
  ${truncateOverflow(3)}
  ${hyphens}

  ${FeedItem}:nth-of-type(1) &, ${FeedItem}:nth-of-type(2) & {
    color: ${({ theme }) => theme.colorWhite};
    
    ${breakpoint.up('large')`
      ${NewsTextLargeStyles}
    `}
  }

  .has-image & {
    color: ${({ theme }) => theme.colorWhite};
  }
`

export const Tags = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  // width: 5.83rem;
  height: 2.22rem;
  padding: 0 0.7rem;
  background: ${({ theme }) => theme.colorBlack};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colorWhite};
  font-size: 0.78rem;
  font-weight: 700;
`

export const StyledDate = styled.span`
  position: absolute;
  top: ${({ theme }) => theme.gutter};
  right: ${({ theme }) => theme.gutter};
  width: 2.22rem;
  height: 2.22rem;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colorBlack};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 700;

  .has-image & {
    color: ${({ theme }) => theme.colorWhite};
    border-color: ${({ theme }) => theme.colorWhite};
  }

  ${FeedItem}:nth-of-type(1) &, ${FeedItem}:nth-of-type(2) & {
    color: ${({ theme }) => theme.colorWhite};
    border-color: ${({ theme }) => theme.colorWhite};
  }
`