import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import {
  isExternalUrl,
  trimUnits
} from '../../utils/helpers'
import {
  Wrapper,
  Inner,
  Title,
  SubTitle,
  LinkGrid,
  GridItem,
  Image,
  ImageContent,
  Overlay,
  LinkText,
  ImageWrapper
} from './styles'
import ExternalLink from '../ExternalLink/externalLink'
import CustomLink from '../CustomLink'
import theme from '../../layout/theme'

const BlockLinks = ({
  title,
  textContent,
  links
}) => {
  const {
    breakpoints: {
      phone,
      max
    },
    gutter
  } = theme

  const sizes = `
    (max-width: ${phone}px) calc((100vw - ${4 * trimUnits(gutter)}rem) / 3), 
    (max-width: ${max}px) calc((100vw - ${16 * trimUnits(gutter)}rem) / 3), 
    470px
  `

  return (
    <Wrapper>
      <Inner>
        <Title>{title}</Title>
        {textContent && <SubTitle>{textContent}</SubTitle>}
        <LinkGrid>
          {links.map((item) => {

            const fluidImage = {
              ...item.image.fluid,
              sizes
            }

            return (
              <GridItem key={item.title}>
                <CustomLink 
                  to={item.url}
                  target={isExternalUrl(item.url) ? '_blank' : ''}
                >
                  <ImageWrapper>
                    <Image fluid={fluidImage} />
                    <Overlay />
                    <ImageContent>
                    </ImageContent>
                    {isExternalUrl(item.url) && <ExternalLink />}
                  </ImageWrapper>
                </CustomLink>
                <LinkText>{item.title}</LinkText>
              </GridItem>
            )})}
        </LinkGrid>
      </Inner>
    </Wrapper>
  )
}

export default BlockLinks

BlockLinks.propTypes = {
  title: PropTypes.string.isRequired,
  textContent: PropTypes.string,
  links: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    image: PropTypes.shape({
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number.isRequired,
        base64: PropTypes.string.isRequired,
        sizes: PropTypes.string.isRequired,
        src: PropTypes.string.isRequired,
        srcSet: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
}

BlockLinks.defaultProps = {
  textContent: 'This is the default sub title.'
}

export const BlockLinksQuery = graphql`
  fragment BlockLinksFragment on DatoCmsLinksRent {
    title
    textContent
    links {
      title
      url
      image {
        fluid(maxWidth: 470, sizes: "107, 178, 214, 359, 470, 940") {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`