// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-gatsby-pages-about-index-js": () => import("./../../../src/gatsby-pages/About/index.js" /* webpackChunkName: "component---src-gatsby-pages-about-index-js" */),
  "component---src-gatsby-pages-contact-index-js": () => import("./../../../src/gatsby-pages/Contact/index.js" /* webpackChunkName: "component---src-gatsby-pages-contact-index-js" */),
  "component---src-gatsby-pages-faq-index-js": () => import("./../../../src/gatsby-pages/FAQ/index.js" /* webpackChunkName: "component---src-gatsby-pages-faq-index-js" */),
  "component---src-gatsby-pages-home-home-js": () => import("./../../../src/gatsby-pages/Home/home.js" /* webpackChunkName: "component---src-gatsby-pages-home-home-js" */),
  "component---src-gatsby-pages-home-q-index-js": () => import("./../../../src/gatsby-pages/HomeQ/index.js" /* webpackChunkName: "component---src-gatsby-pages-home-q-index-js" */),
  "component---src-gatsby-pages-news-index-js": () => import("./../../../src/gatsby-pages/News/index.js" /* webpackChunkName: "component---src-gatsby-pages-news-index-js" */),
  "component---src-gatsby-pages-real-estate-index-js": () => import("./../../../src/gatsby-pages/RealEstate/index.js" /* webpackChunkName: "component---src-gatsby-pages-real-estate-index-js" */),
  "component---src-gatsby-pages-residence-index-js": () => import("./../../../src/gatsby-pages/Residence/index.js" /* webpackChunkName: "component---src-gatsby-pages-residence-index-js" */),
  "component---src-gatsby-pages-single-area-template-index-js": () => import("./../../../src/gatsby-pages/SingleAreaTemplate/index.js" /* webpackChunkName: "component---src-gatsby-pages-single-area-template-index-js" */),
  "component---src-gatsby-pages-single-news-template-index-js": () => import("./../../../src/gatsby-pages/SingleNewsTemplate/index.js" /* webpackChunkName: "component---src-gatsby-pages-single-news-template-index-js" */),
  "component---src-gatsby-pages-specimen-specimen-js": () => import("./../../../src/gatsby-pages/Specimen/specimen.js" /* webpackChunkName: "component---src-gatsby-pages-specimen-specimen-js" */),
  "component---src-gatsby-pages-subpage-subpage-js": () => import("./../../../src/gatsby-pages/Subpage/subpage.js" /* webpackChunkName: "component---src-gatsby-pages-subpage-subpage-js" */)
}

