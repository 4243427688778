import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import GlobalStyles from '../../layout/globalStyles'
import theme from '../../layout/theme'
import CSSReset from '../../layout/reset'
import Fonts from '../../fonts/fonts'
import RTEStyles from '../../layout/rteStyles'
import SEO from '../SEO'
import Header from '../Header'
import MenuMobile from '../MenuMobile'
import Footer from '../Footer'
import CookieBar from '../CookieBar/cookieBar'

export default ({ children, data }) => (
  <ThemeProvider theme={theme}>
    <>
      <CSSReset />
      <Fonts />
      <GlobalStyles />
      <RTEStyles />
      <CookieBar />
      <SEO tags={data?.datoCms?.seo?.tags} />
      {/* DOM structure and classes needed for sticky footer */}
      <div className="site-wrapper">
        <div className="content">
          <Header />
          <MenuMobile />
          <main>{children}</main>
        </div>
        <Footer />
      </div>
    </>
  </ThemeProvider>
)
