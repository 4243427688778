export default class KallfeltError extends Error {
  constructor(type = 'validation', ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, KallfeltError)
    }

    this.name = 'KallfeltError'
    // Custom debugging information
    this.date = new Date()
  }
}