import styled from 'styled-components'
import {
  breakpoint, Halign, BlockPaddingTop
} from '../../layout/mixins'
import Quote from '../Quote'

export const Grid = styled(Halign)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
`
export const StyledQuote = styled(Quote)`
  width: 100%;
  padding: 0 ${({ theme }) => theme.gutterXLarge} ${({ theme }) => theme.gutterXLarge} ${({ theme }) => theme.gutterXLarge};

  &:last-child {
    padding-bottom: 0;
  }
  
  ${breakpoint.up('phone')`
    width: 50%;
    padding-bottom: 0;
  `}
`