import styled from 'styled-components'
import {
  HalignContent,
  Heading1,
  breakpoint
} from '../../layout/mixins'
import Image from 'gatsby-image'
import Button from '../Button'

export const Wrapper = styled.section`
  position: relative;
`

export const ImageWrapper = styled(Image)`
  position: absolute;
  width: 100%;
  height: 100%;
`

export const Inner = styled(HalignContent)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Content = styled.div`
  width: 100%;
  max-width: 662px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 8rem;
  padding-bottom: ${({ theme }) => theme.blockpadding};
`

export const Heading = styled(Heading1)`
  color: ${({ theme }) => theme.textcolorSecondary};
  margin-bottom: 16px;

  ${breakpoint.up('tablet')`
    margin-bottom: 24px;
  `}
`

export const Preamble = styled.div`
  color: ${({ theme }) => theme.textcolorSecondary};
  margin-bottom: 32px;

  ${breakpoint.up('tablet')`
    margin-bottom: 70px;
  `}
`

export const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${breakpoint.up('tablet')`
    flex-direction: row;
  `}
`

export const StyledButton = styled(Button)`
  min-width: 140px;
  margin-bottom: ${({ theme }) => theme.gutter};

  ${breakpoint.up('tablet')`
    min-width: 9rem;
    margin-right: 8%;
    margin-bottom: 0;
  `}

  &:last-of-type {
    margin-right: 0;
    margin-bottom: 0;
  }
`