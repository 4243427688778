import styled from 'styled-components'
import {
  breakpoint,
  Heading2,
  ParagraphStyles,
  truncateOverflow
} from '../../layout/mixins'
import ArrowIcon from '../../layout/icons/arrow-short.svg'
import CustomLink from '../CustomLink'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.gutter};

  ${breakpoint.up('tablet')`
    grid-template-columns: 1fr 2fr;
  `}
`

export const TextItem = styled.div`
  padding-right: ${({ theme }) => theme.gutterLarge};
`

export const Title = styled(Heading2)`
  color: ${({ theme }) => theme.textcolorPrimary};

  ${breakpoint.up('desktop')`
    margin-bottom: ${({ theme }) => theme.gutterMedium};
  `}    
`

export const TextContent = styled.div`
  ${ParagraphStyles}
  color: ${({ theme }) => theme.textcolorPrimary};
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.gutterMedium};
`

export const FAQItem = styled.div`
  display: none;

  ${breakpoint.up('tablet')`
    display: block;
  `}
`

export const FAQGrid = styled.div`
  display: flex;
  justify-content: space-between;

  ${breakpoint.up('tablet')`
    flex-direction: column;
  `}

  ${breakpoint.up('desktop')`
    flex-direction: row;
  `}
`

export const FAQCard = styled(CustomLink)`
  position: relative;
  border: 2px solid ${({ theme }) => theme.colorBlack};
  border-radius: ${({ theme }) => theme.buttonRadius};
  padding: ${({ theme }) => theme.gutterLarge};
  padding-right: ${({ theme }) => theme.gutterXXLarge};
  transition: background ${({ theme }) => theme.transitionRegular};

  ${breakpoint.up('desktop')`
    width: calc(50% - ${({ theme }) => theme.gutterSmall});
  `}

  &:hover {
    background: ${({ theme }) => theme.colorBlack};
    a {
      color: ${({ theme }) => theme.colorWhite};
      transition: color ${({ theme }) => theme.transitionRegular};
    }
  }

  &:first-of-type {
    margin-bottom: ${({ theme }) => theme.gutterMedium};

    ${breakpoint.up('desktop')`
      margin-bottom: 0;
    `}
  }

`

export const FAQQuestion = styled.span`
  display: block;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: ${({ theme }) => theme.gutter};
  transition: color ${({ theme }) => theme.transitionRegular};

  ${FAQCard}:hover & {
    color: ${({ theme }) => theme.colorWhite};
  }
`

export const FAQAnswer = styled.span`
  ${truncateOverflow(4)}
  font-size: 0.78rem;
  transition: color ${({ theme }) => theme.transitionRegular};

  ${FAQCard}:hover & {
    color: ${({ theme }) => theme.colorWhite};
  }
`

export const Arrow = styled(ArrowIcon)`
  position: absolute;
  bottom: ${({ theme }) => theme.gutterMedium};
  right: ${({ theme }) => theme.gutterMedium};
  width: 0.833rem;
  fill: ${({ theme }) => theme.colorBlack};
  transition: fill ${({ theme }) => theme.transitionRegular};

  ${FAQCard}:hover & {
    fill: ${({ theme }) => theme.colorWhite};
  }
`
