import React, {
  useState,
  useEffect
} from 'react'
import { navigate, graphql } from 'gatsby'
import {
  getFaqsByTag,
  getFaqBySlug,
  getFaqsBySearchTerm
 } from '../../lib/CMSFetchService'
import KallfeltError from '../../lib/KallfeltError'
import {
  removeDuplicates
} from '../../utils/helpers'
import {
  Hero,
  HeroContent,
  Title,
  Preamble,
  Filter,
  Category,
  Container,
  Divider,
  LoadingOverlay,
  Controls,
  ControlsTitle,
  Form,
  Input,
  InputGroup,
  SearchIcon,
  SearchButton,
  SearchErrorMessage
} from './styles'
import {
  ModalContent,
  TextItem,
  Question,
  Answer,
  ImageItem,
  DatoItemImage
} from '../../components/FAQFeed/styles'
import { addSizes } from '../../components/FAQFeed/faqFeed'
import Section from '../../components/Section/section'
import FAQLinks from '../../components/FAQLinks/faqLinks'
import FAQFeed from '../../components/FAQFeed/faqFeed'
import Button from '../../components/Button'
import Modal from '../../components/Modal'

const FAQ = ({ data, location }) => {

  const {
    heroTitle,
    heroPreamble,
    faqLinks
  } = data.datoCmsFaqPage

  const {
    edges: categories
  } = data.categories

  const {
    totalCount: totalCountUnfiltered,
    edges: allFaqs
  } = data.allFaqs

  // Local state
  const minLoadTime = 500

  // Faq item state
  const [faqs, setFaqs] = useState(allFaqs.map(({ node }) => node))

  // Category filter state
  const [activeCategory, setActiveCategory] = useState(null)
  const [isResultFiltered, setIsResultFiltered] = useState(false)

  // Pagination state
  const [page, setPage] = useState(1)
  const [totalCount, setTotalCount] = useState(totalCountUnfiltered)
  const [firstBatch] = useState(12)
  const [subsequentBatches] = useState(12)

  // Loading state
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [isCategoryLoading, setIsCategoryLoading] = useState(false)
  const [isSearchLoading, setIsSearchLoading] = useState('')

  // Scearch state
  const [searchTerm, setSearchTerm] = useState('')
  const [searchErrorMessage, setSearchErrorMessage] = useState('')

  // Single faq state
  const [singleFaq, setSingleFaq] = useState(false)

  // Subscribe to hash change
  useEffect(() => {

    if (!location.hash) {
      setSingleFaq(false)
      return
    }

    getFaqBySlug(location.hash.replace('#', ''))
      .then((response) => setSingleFaq(response?.data?.faq))
      .catch((error) => {
        console.log(error)
      })

  }, [location.hash])

  // Subscribe to active category change
  useEffect(() => {
    // Fetch news articles based on active category
    // but not on inital render
    if (!activeCategory) return
    setIsCategoryLoading(true)

    getFaqsByTag(activeCategory, getFirstValue(), getSkipValue())
      .then((response) => {
        setTotalCount(response.data.count)
        setIsResultFiltered(false)
        setSearchErrorMessage(false)

        setTimeout(() => {
          setFaqs(response.data.allFaqs)
          setIsCategoryLoading(false)
        }, minLoadTime)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [activeCategory])

  // Subscribe to page change
  useEffect(() => {
    // Fetch news articles with incremented page variable
    if (page === 1) return

    if (isResultFiltered) {
      handleSearch();
      return;
    }

    setIsPageLoading(true)

    getFaqsByTag(activeCategory, getFirstValue(), getSkipValue())
      .then((response) => {
        console.log('response', response)
        setFaqs(prevFaqs => removeDuplicates([...prevFaqs, ...response.data.allFaqs]))
        setTimeout(() => setIsPageLoading(false), minLoadTime)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [page])

  const handleCategoryChange = (category) => {
    if (category === activeCategory) return
    setIsResultFiltered(false)
    setSearchTerm('')
    setPage(1)
    setActiveCategory(category)
  }

  const getFirstValue = () => {
    console.log('page', page)
    if (page > 1) {
      console.log('parseInt(page * subsequentBatches, 10)', parseInt(page * subsequentBatches, 10))
      return parseInt(page * subsequentBatches, 10);
    }
    return firstBatch
  }

  const getSkipValue = () => {
    if (page > 1) {
      return firstBatch + ((page - 2) * subsequentBatches)
    }
    return 0
  }

  const handlePageIncrement = () => {
    console.log('faqs.length', faqs.length)
    console.log('totalCount', totalCount)
    if (faqs.length < totalCount) {
      setPage(page + 1)
    }
  }

  const handleSearch = () => {
    if (!searchTerm) {
      setSearchErrorMessage('Ange en sökterm')
      return
    }

    setActiveCategory(null)
    setIsSearchLoading(true)
    setSearchErrorMessage(false)
    
    if(page <= 1) {
      setFaqs([])
    }

    getFaqsBySearchTerm(searchTerm, getFirstValue(), getSkipValue())
      .then((response) => {
        setTotalCount(response.data.count)
        setIsResultFiltered(true)
        setTimeout(() => {
          setFaqs(prevFaqs => {
            console.log('prevFaqs', prevFaqs) 
            return removeDuplicates([...prevFaqs, ...response.data.allFaqs])
          })
          //setSearchTerm('')
          setIsSearchLoading(false)
        }, minLoadTime)
      })
      .catch((error) => {
        setIsSearchLoading(false)
        setFaqs([])

        if (error instanceof KallfeltError) {
          setSearchErrorMessage(error.message)
          setTotalCount(0)
        }
      })
  }

  return (
    <>
      <Hero>
        <HeroContent>
          <Title>{heroTitle}</Title>
          <Preamble
            className="rte"
            dangerouslySetInnerHTML={{ __html: heroPreamble }}
          />
        </HeroContent>
      </Hero>
      <Section>
        <FAQLinks links={faqLinks.links} />
      </Section>
      <Section
        verticalPadding="none"
      >
        <Divider />
      </Section>
      <Section>
        <Controls>
          <ControlsTitle>Sök bland vanliga frågor</ControlsTitle>
          <Filter>
            <Category
              key="all-filter"
              onClick={() => handleCategoryChange('all')}
              active={activeCategory === 'all'}
            >
              Alla
            </Category>

            {categories.map(({ node }) => (
              <Category
                key={node.originalId}
                onClick={() => handleCategoryChange(node.originalId)}
                active={node.originalId === activeCategory}
              >
                {node.title}
              </Category>
            ))}
          </Filter>
          <Form>
            <InputGroup>
              <Input
                type="text"
                name="search-term"
                placeholder="Sök"
                onChange={({ target }) => setSearchTerm(target.value)}
                value={searchTerm}
              />
              <SearchIcon
                name="search"
                inputEmpty={searchTerm === '' || false}
              />
              {!searchErrorMessage ? null : <SearchErrorMessage>{searchErrorMessage}</SearchErrorMessage>}
            </InputGroup>
            <SearchButton
              type="button"
              colorTheme="regular"
              hoverEffect={false}
              text={isSearchLoading ? 'Hämtar...' : 'Sök'}
              onClick={handleSearch}
            />
          </Form>

        </Controls>
      </Section>
      <Section
        verticalPadding="none"
      >
        <Divider className="margin-bottom" />
      </Section>
      <Section
        verticalPadding="bottom"
      >
        <Container>
          {(faqs.length < 1 && !isSearchLoading && !isCategoryLoading) && (
            <LoadingOverlay>
              Din sökning gav inga resultat.
            </LoadingOverlay>
          )}

          {(isCategoryLoading || isSearchLoading) && (
            <LoadingOverlay>
              Läser in fler...
            </LoadingOverlay>
          )}

          <FAQFeed
            faqs={faqs}
            isResultFiltered={isResultFiltered}
          />
        </Container>
      </Section>
      {faqs.length < totalCount && !isCategoryLoading && (
        <Section
          verticalPadding="bottom"
          alignContent="center"
        >
          <Button
            text={isPageLoading ? 'Hämtar...' : 'Läs in fler'}
            onClick={handlePageIncrement}
          />
        </Section>
      )}

      {/* If hash in url show single faq in modal */}
      {singleFaq && (
        <Modal toggle={() => {
          setSingleFaq(false)
          navigate(location.pathname)
        }}>
          <Section
            horizontalPadding="content"
            verticalPadding="none"
          >
            <ModalContent>
              <TextItem>
                <Question>{singleFaq.question}</Question>
                <Answer
                  className="rte"
                  dangerouslySetInnerHTML={{ __html: singleFaq.answer }}
                />
              </TextItem>
              <ImageItem>
                {singleFaq.image && <DatoItemImage data={singleFaq?.image?.responsiveImage} style={{ position: 'absolute' }}/>}
              </ImageItem>
            </ModalContent>
          </Section>
        </Modal>
      )}
    </>
  )
}

export default FAQ

export const query = graphql`
  query {
    datoCmsFaqPage {
      id
      slug
      heroTitle
      heroPreamble
      faqLinks {
        ...FAQLinksFragment
      }
      seo: seoMetaTags {
        tags
      }
    }
    categories: allDatoCmsFaqCategory(sort: {fields: position}) {
      edges {
        node {
          title
          originalId
        }
      }
    }
    allFaqs: allDatoCmsFaq(sort: {fields: question}) {
      edges {
        node {
          id
          question
          answer
          image {
            fluid(maxWidth: 956, sizes: "347, 694, 274, 548, 395, 478, 956") {
              ...GatsbyDatoCmsFluid
            }
          }
          slug
          category {
            title
          }
        }
      }
    }    
  }
`