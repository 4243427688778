import styled from 'styled-components'
import {
  breakpoint,
  Heading2,
  ParagraphStyles
} from '../../layout/mixins'

export const Grid = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint.up('desktop')`
    flex-direction: row;
  `}  
`

export const TextItem = styled.div`
  width: 100%;

  ${breakpoint.up('tablet')`
    padding-right: ${({ theme }) => theme.gutterLarge};
  `}

  ${breakpoint.up('desktop')`
    padding-right: 6rem;
    width: 43%;
  `}
`

export const Text = styled.div``

export const AdditionalText = styled.div`
  padding-top: ${({ theme }) => theme.gutterLarge};
`

export const ImageItem = styled.div`
  width: 100%;
  display: none;

  ${breakpoint.up('desktop')`
    display: block;
    width: 57%;
  `}
`

export const Title = styled(Heading2)`
  color: ${({ theme }) => theme.textcolorPrimary};

  ${breakpoint.up('desktop')`
    margin-bottom: ${({ theme }) => theme.gutterMedium};
  `}  
`

export const TextContent = styled.div`
  ${ParagraphStyles}
  color: ${({ theme }) => theme.textcolorPrimary};
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.gutterLarge};

  ${breakpoint.up('tablet')`
    margin-bottom: ${({ theme }) => theme.gutterLarge};
  `}
`

export const Steps = styled.ul``

export const Step = styled.li`
  margin-bottom: ${({ theme }) => theme.gutter};

  &:last-of-type {
    margin-bottom: 0;
  }
`

export const StepGroup = styled.div`
  display: flex;
  align-items: center;  
`

export const StepTitle = styled.span`
  margin-bottom: 0;
  margin-left: ${({ theme }) => theme.gutter};
`

export const StepNumber = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colorAccent};
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.colorWhite};
  padding-bottom: 0.2rem;

  ${breakpoint.up('max')`
    width: 4rem;
    height: 4rem;
    font-size: 2.22rem;
  `} 
`

export const Caption = styled.div`
  position: relative;
  width: 50%;
  margin-top:  ${({ theme }) => theme.gutter};
  padding-left: ${({ theme }) => theme.gutter};
  font-size: 0.778rem;

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background: ${({ theme }) => theme.colorBlack};
  }
`