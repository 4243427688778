import styled, { css } from 'styled-components'
import siteTheme from '../../layout/theme'
import CustomLink from '../CustomLink'

export const Wrapper = css`
  display: inline-block;
  padding: 0 ${siteTheme.gutter};
  height: 3rem;
  line-height: 3rem;
  outline: none;
  color: ${({ theme }) => theme.textColor};
  cursor: pointer;
  border: ${({ theme }) => theme.border};
  border-radius: ${siteTheme.buttonRadius};
  transition: ${`color ${siteTheme.transitionRegular}, background ${siteTheme.transitionRegular}, border-color ${siteTheme.transitionRegular}`};
  background: ${({ theme }) => theme.backgroundColor};

  &:focus {
    outline: none;
  }

  ${({ hoverEffect, theme }) => hoverEffect && `
    &:hover {
      background: ${theme.hoverBackground};
      color: ${theme.hoverColor};
      border-color: ${theme.borderColorHover};
    }
  `}

  svg {
    transition: all ${siteTheme.transitionRegular};
  }

  ${({ hoverEffect, theme }) => hoverEffect && `
    &:hover svg {
      fill: ${theme.hoverColor};
    }  
  `}  
`

export const ButtonWrapper = styled.button`
  ${Wrapper}
`

export const LinkWrapper = styled(CustomLink)`
  ${Wrapper}
`

export const ButtonInner = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

export const ButtonText = styled.span`
  font-family: ${siteTheme.fontfamilyPrimary};
  font-size: 1rem;
  font-weight: 700;
  white-space: nowrap;
  width: 100%;
`

export const themes = {
  regular: {
    backgroundColor: 'transparent',
    hoverBackground: siteTheme.colorBlack,
    textColor: siteTheme.textcolorPrimary,
    hoverColor: siteTheme.textcolorSecondary,
    border: `2px solid ${siteTheme.colorBlack}`,
    borderColorHover: siteTheme.colorBlack
  },
  light: {
    backgroundColor: 'transparent',
    hoverBackground: siteTheme.colorWhite,
    textColor: siteTheme.textcolorSecondary,
    hoverColor: siteTheme.textcolorPrimary,
    border: `2px solid ${siteTheme.colorWhite}`,
    borderColorHover: siteTheme.colorWhite
  },
  accent: {
    backgroundColor: siteTheme.colorAccent,
    hoverBackground: siteTheme.colorAccent,
    textColor: siteTheme.colorAccent,
    hoverColor: siteTheme.textcolorSecondary,
    border: `2px solid ${siteTheme.colorAccent}`,
    borderColorHover: siteTheme.colorAccent
  },
  white: {
    backgroundColor: siteTheme.colorWhite,
    hoverBackground: siteTheme.colorBlack,
    textColor: siteTheme.colorBlack,
    hoverColor: siteTheme.colorWhite,
    border: `2px solid ${siteTheme.colorWhite}`,
    borderColorHover: siteTheme.colorBlack
  }
}