import styled from 'styled-components'
import {
  Heading1,
  Heading4Styles,
  breakpoint
} from '../../layout/mixins'

export const Container = styled.article`
    flex: 50% 0 0;

    ${breakpoint.up('tablet')`
      flex: 33% 0 0;
    `}

    ${breakpoint.up('large')`
      flex: 25% 0 0;
    `}

    padding: 0 ${({ theme }) => theme.gutterSmall};
  `

export const Heading = styled.h3`
    ${Heading4Styles}

  `

export const Content = styled.div`
  padding: ${({ theme }) => theme.gutterSmall} 0 ${({ theme }) => theme.gutterMedium} 0;
`
export const Phone = styled.a`
  font-size: 0.9em;
`
export const Email = styled.span`
  font-size: 0.9em;
  white-space: break;
`
export const Title = styled.span`
  font-size: 0.9em;
`
export const Information = styled.span`
  font-size: 0.9em;
`