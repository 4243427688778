import styled from 'styled-components'
import Image from 'gatsby-image'
import {
  breakpoint,
  ParagraphSmallStyles,
  truncateOverflow,
  HalignContent
} from '../../layout/mixins'
import Pin from '../../layout/icons/pin.svg'
import CustomLink from '../CustomLink'

export const Map = styled.div`
  position: relative;
  min-height: 40rem;
  border-radius: ${({ theme }) => theme.mapRadius};
  overflow: hidden;
`

export const Marker = styled.div`
  position: relative;
  width: 22px;
  height: 31px;
`

export const Title = styled.span`
  font-family: ${({ theme }) => theme.fontfamilyPrimary};
  font-weight: 700;
  font-size: 1.33rem;
  line-height: 1.1em;
  display: block;
  margin-bottom: ${({ theme }) => theme.gutterSmall};
`

export const Description = styled.div`
  ${ParagraphSmallStyles}
  ${truncateOverflow('6')}
`

export const StyledMarker = styled(Pin)`
  position: absolute;
  top: 0;
  left: 0;
  fill: ${({ theme }) => theme.colorAccent};
  width: 22px;
  height: 31px;
  display: block;
  z-index: -1;

  ${Marker}:hover & {
    z-index: 2;
  }
`

export const InfoBox = styled.div`
  position: absolute;
  width: 25.5rem;
  height: 16rem; 
  background: white;
  left: calc(25.5rem * 0.6 * -1 + 11px); // Custom positioning
  bottom: calc(100% - 0.5rem);
  opacity: 0;
  pointer-events: none;
  transition: ${({ theme }) => `opacity ${theme.transitionRegular}, transform ${theme.transitionRegular}`};
  transform: translateY(5px);
  
  ${Marker}:hover & {
    display: block;
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }
`

export const InfoGrid = styled.div`
  display: flex;
  height: 100%;
`

export const TextItem = styled.div`
  position: relative;
  width: 60%;
  padding: ${({ theme }) => theme.gutterLarge};
`

export const ImageItem = styled.div`
  width: 40%;
`

export const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
`

export const ReadMore = styled(CustomLink)`
  ${ParagraphSmallStyles}
  text-decoration: underline;
  position: absolute;
  bottom: ${({ theme }) => theme.gutter};
  right: ${({ theme }) => theme.gutter};
`