import React from 'react'
import { createGlobalStyle } from 'styled-components'
import {
  ParagraphStyles,
  Heading1Styles,
  Heading2Styles,
  Heading3Styles,
  Heading4Styles
} from './mixins'

const RTEStyles = createGlobalStyle`
  .rte {
    p {
      ${ParagraphStyles}
      margin-bottom: 1em;
    }

    p:last-of-type {
      margin-bottom: 0;
    }

    p + h1, p + h2, p + h3, p + h4, p + h5, p + h6 {
      margin-top: 2rem;
    }

    h1 {
      ${Heading1Styles}
    }
  
    h2 {
      ${Heading2Styles}
    }
  
    h3 {
      ${Heading3Styles}
    }
  
    h4 {
      ${Heading4Styles}
    }

    h5 {
      ${Heading4Styles}
    }

    h6 {
      ${Heading4Styles}
    }

    a {
      text-decoration: underline;
      color: inherit;
    }
    ul {
      list-style-type: disc;
      padding-left: 1em;
    }
    ul li {
      line-height: 1.4em;
      margin-bottom: 0.5em;
    }
  }
`
export default RTEStyles