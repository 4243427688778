import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

const Portal = ({ children, target }) => {
  const portalTarget = document.querySelector(target)
  return ReactDOM.createPortal(
    children,
    portalTarget
  )
}

Portal.propTypes = {
  target: PropTypes.string
}

Portal.defaultProps = {
  target: '#modal'
}

export default Portal