export const getIcon = icon => {
  switch (icon) {
    case "arrow-down":
      return import("/src/layout/icons/arrow-down.svg")
    case "arrow-short":
      return import("/src/layout/icons/arrow-short.svg")
    case "arrow-long":
      return import("/src/layout/icons/arrow-long.svg")
    case "arrow-thin":
      return import("/src/layout/icons/arrow-thin.svg")
    case "badge":
      return import("/src/layout/icons/badge.svg")
    case "bike":
      return import("/src/layout/icons/bike.svg")
    case "box":
      return import("/src/layout/icons/box.svg")
    case "car":
      return import("/src/layout/icons/car.svg")
    case "close":
      return import("/src/layout/icons/close.svg")
    case "computer":
      return import("/src/layout/icons/computer.svg")
    case "external-link":
      return import("/src/layout/icons/external-link.svg")
    case "fire":
      return import("/src/layout/icons/fire.svg")
    case "forms":
      return import("/src/layout/icons/forms.svg")
    case "half-romb":
      return import("/src/layout/icons/half-romb.svg")
    case "hamburger":
      return import("/src/layout/icons/hamburger.svg")
    case "house":
      return import("/src/layout/icons/house.svg")
    case "info":
      return import("/src/layout/icons/info.svg")
    case "insect":
      return import("/src/layout/icons/insect.svg")
    case "logo-full":
      return import("/src/layout/icons/logo-full.svg")
    case "mypages":
      return import("/src/layout/icons/mypages.svg")
    case "pin":
      return import("/src/layout/icons/pin.svg")
    case "public-transportation":
      return import("/src/layout/icons/public-transportation.svg")
    case "return-arrow":
      return import("/src/layout/icons/return-arrow.svg")
    case "rules":
      return import("/src/layout/icons/rules.svg")
    case "search":
      return import("/src/layout/icons/search.svg")
    case "speaking-bubble":
      return import("/src/layout/icons/speaking-bubble.svg")
    default:
      return import("/src/layout/icons/arrow-down.svg")
  }
}
