import styled from 'styled-components'
import Img from 'gatsby-image'
import {
  HalignContent,
  Heading3,
  breakpoint
} from '../../layout/mixins'
import ExternalIcon from '../../layout/icons/external-link.svg'
import CustomLink from '../CustomLink'

export const Wrapper = styled.div`
  position: relative;
  padding-top: ${({ theme }) => theme.blockpadding};
  padding-bottom: ${({ theme }) => theme.blockpadding};

  ${breakpoint.up('large')`
    height: 60vh;
  `}
`

export const StyledImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`

export const Inner = styled(HalignContent)`
  height: 100%;
`

export const Content = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

export const Card = styled(CustomLink)`
  display: block;
  width: 30rem;
  padding: ${({ theme }) => theme.gutterLarge};
  padding-right: 6rem;
  background: url(/images/tile.svg);
  background-size: 50%;
  position: relative;
`

export const Heading = styled(Heading3)`
  color: ${({ theme }) => theme.textcolorSecondary};
`

export const TextContent = styled.div`
  color: ${({ theme }) => theme.textcolorSecondary};
`

export const ExternalLink = styled(ExternalIcon)`
  position: absolute;
  bottom: ${({ theme }) => theme.gutterMedium};
  right: ${({ theme }) => theme.gutterMedium};
  width: 0.833rem;
  height: 0.833rem;
  fill: ${({ theme }) => theme.colorWhite};
  transition: fill ${({ theme }) => theme.transitionRegular};
`