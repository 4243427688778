import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import {
  Article, Widget
} from './styles'
import TextWithImage from '../../components/TextWithImage'
import Section from '../../components/Section/section'

const HomeQ = ({ data }) => {
  const {
    hero: [hero],
    textContent
  } = data.datoCmsHomeqPage

  const addHomeQScript = () => {
    var head = document.head || document.getElementsByTagName("head")[0];
    var script = document.createElement("script");
        script.setAttribute("src", "https://widgets.homeq.se/widgets/overview.js");
        script.setAttribute("type", "text/javascript");
        script.setAttribute("charset", "utf8");
        script.setAttribute("async", "");
        head.appendChild(script);
  }

  useEffect(() => {
    addHomeQScript();
  }, [])

  return (
    <>
      <Helmet>
        <link rel="stylesheet" type="text/css" href="https://widgets.homeq.se/widgets/overview.css" />
      </Helmet>
      <Section
        horizontalPadding="max"
        verticalPadding="none"
        fullMobile
      >
        <TextWithImage
          {...hero}
          headingSize={1}
          addShortcut
          asHero
        />
      </Section>
      <Section
       horizontalPadding="max"
       verticalPadding="top-bottom"
       fullMobile
      >
          <Widget id="homeq-anchor" data-company="368"  />
      </Section>
    </>
  )
}

export default HomeQ

export const query = graphql`
  query HomeQuery {
    datoCmsHomeqPage {
      hero {
        ...TextWithImageFragment
      }    
      seoMetaTags {
        tags
      }
    }
  }
`
