import styled from 'styled-components'
import {
  Heading2,
  Heading4Styles,
  breakpoint
} from '../../layout/mixins'
import Icon from '../../components/Icon'

export const Grid = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint.up('tablet')`
    flex-direction: row;
  `}
`

export const InfoItem = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.gutterLarge};

  ${breakpoint.up('tablet')`
    width: 60%;
    margin-bottom: 0;
  `}  
`

export const FormItem = styled.div`
  width: 100%;

  ${breakpoint.up('tablet')`
    width: 40%;
  `}  
`

export const InfoTag = styled.div`
  font-size: 0.778rem;
`

export const Title = styled(Heading2)``

export const AddressTitle = styled.div`
  font-size: 1.11rem;
  font-weight: 700;
`

export const PostalAddress = styled.div`
  margin-bottom: ${({ theme }) => theme.gutter};
`

export const VisitingAddress = styled.div`
  margin-bottom: ${({ theme }) => theme.gutterLarge};
`

export const LinkList = styled.div`
  display: inline-flex;
  flex-direction: column;
`

export const ContactLink = styled.a`
  ${Heading4Styles}
  align-items: center;
`

export const PhoneLink = styled(ContactLink)`
  margin-bottom: ${({ theme }) => theme.gutterSmall};
`

export const EmailLink = styled(ContactLink)``

export const StyledIcon = styled(Icon)`
  && {
    width: 0.7em;
    height: 0.7em;
    margin-right: ${({ theme }) => theme.gutter};
  }
`

export const Filter = styled.ul`
  display: flex;
  justify-content: center;
`
export const Category = styled.li`
  position: relative;
  padding: ${({ theme }) => `0 ${theme.gutterSmall}`};
  cursor: pointer;
  color: ${({ theme }) => theme.colorBlack};

  &:after {
    content: '/';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(60%, -50%);
    color: ${({ theme }) => theme.colorBlack};
  }

  &:last-of-type:after {
    display: none;
  }

  ${({ active, theme }) => active && `
    text-decoration: underline;
    color: ${theme.colorAccent};
  `}
`

export const Center = styled.div`
  text-align: center;
`
export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.gutterLarge};
  justify-content: flex-start;
`