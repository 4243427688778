import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import PropTypes from 'prop-types'
import siteTheme from '../../layout/theme'
import { trimUnits } from '../../utils/helpers'
import {
  Grid,
  TextItem,
  Text,
  AdditionalText,
  ImageItem,
  Title,
  TextContent,
  Steps,
  Step,
  StepGroup,
  StepTitle,
  StepNumber,
  Caption
} from './styles'

const FindResidence = ({
  title,
  textContent,
  step1,
  step2,
  step3,
  additionalText,
  image,
  caption
}) => {
  const {
    breakpoints: {
      desktop
    },
    gutter
  } = siteTheme

  const sizes = `
    (max-width: ${desktop}px) calc((100vw - ${14 * trimUnits(gutter)}rem) * 0.57),
    825px
  `

  const fluidImage = {
    ...image.fluid,
    sizes
  }

  return (
    <Grid>
      <TextItem>
        <Text>
          <Title>{title}</Title>
          <TextContent
            className="rte"
            dangerouslySetInnerHTML={{ __html: textContent }}
          />
        </Text>
        <Steps>
          <Step>
            <StepGroup>
              <StepNumber>1</StepNumber>
              <StepTitle
                className="rte"
                dangerouslySetInnerHTML={{ __html: step1 }}
              />
            </StepGroup>
          </Step>
          <Step>
            <StepGroup>
              <StepNumber>2</StepNumber>
              <StepTitle
                className="rte"
                dangerouslySetInnerHTML={{ __html: step2 }}
              />
            </StepGroup>
          </Step>
          <Step>
            <StepGroup>
              <StepNumber>3</StepNumber>
              <StepTitle
                className="rte"
                dangerouslySetInnerHTML={{ __html: step3 }}
              />
            </StepGroup>
          </Step>
        </Steps>
        { additionalText !== '' ? (
          <AdditionalText>
            <TextContent
              className="rte"
              dangerouslySetInnerHTML={{ __html: additionalText }}
            />
          </AdditionalText>
        ) : null}
      </TextItem>
      <ImageItem>
        <Image
          fluid={fluidImage}
        />
        {caption && (
          <Caption
            className="rte"
            dangerouslySetInnerHTML={{ __html: caption }}
          />
        )}
      </ImageItem>
    </Grid>
  )
}

export default FindResidence

FindResidence.propTypes = {
  title: PropTypes.string.isRequired,
  textContent: PropTypes.string,
  step1: PropTypes.string.isRequired,
  step2: PropTypes.string.isRequired,
  step3: PropTypes.string.isRequired,
  additionalText: PropTypes.string,
  image: PropTypes.shape({
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number.isRequired,
      base64: PropTypes.string.isRequired,
      sizes: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
      srcSet: PropTypes.string.isRequired,
    }).isRequired
  }).isRequired,
  caption: PropTypes.string.isRequired
}

FindResidence.defaultProps = {
  textContent: 'This is the text content',
  additionalText: ''
}

export const FindResidenceResidenceQuery = graphql`
  fragment FindResidenceFragment on DatoCmsFindResidence {
    title
    textContent
    step1
    step2
    step3
    additionalText
    image {
      fluid(maxWidth: 1650, sizes: "450, 900, 1650") {
        ...GatsbyDatoCmsFluid
      }
    }
    caption
  }
`