import styled from 'styled-components'
import { breakpoint, Heading2 } from '../../layout/mixins'
import { StyledMessage } from '../Input/styles'
// import CheckIcon from '../../layout/icons/check.svg'
import CloseIcon from '../../layout/icons/close.svg'
import Button from '../Button'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 30rem;
  z-index: ${({ theme }) => theme.zLevel4};

  ${breakpoint.up('tablet')`
    max-width: none;
  `}

  ${StyledMessage} {
    top: calc(100% + 0.35rem);
    font-size: 0.7rem;
  }
`

export const Form = styled.form``

export const Content = styled.div`
  // width: 100%;
  // height: 100%;
  // position: relative;
  // padding: ${({ theme }) => theme.gutter};
  // padding-top: ${({ theme }) => theme.gutterXLarge};
  // overflow: auto;

  // ${breakpoint.up('desktop')`
  //   width: auto;
  //   height: ${({ theme: { headerheight } }) => `calc(100vh - ${headerheight})`};
  //   padding: ${({ theme }) => theme.gutterXLarge};
  //   padding-bottom: 0;
  // `}
`

export const Heading = styled(Heading2)`
  margin-bottom: ${({ theme }) => theme.gutter};
`

export const ButtonGroup = styled.div`
  position: relative;
`

export const StyledButton = styled(Button)`
  display: block;
`

export const SubmitMessage = styled(StyledMessage)`
  && {
    position: absolute;
    width: 100%;
    display: block;
    top: calc(100% + ${({ theme }) => theme.gutter});
    text-align: center;
    color: ${({ theme, type }) => (type === 'error' ? theme.colorDanger : theme.textcolorPrimary)};
  }
`

// export const Consent = styled.div`
//   margin-bottom: ${({ theme }) => theme.gutterLarge};
// `

// export const ConsentContent = styled.div`
//   position: relative;
//   display: flex;
//   align-items: center;
// `

// export const CheckboxWrapper = styled.div`
//   position: relative;
// `

// export const CheckboxCheck = styled(CheckIcon)`
//   position: absolute;
//   top: 3px;
//   left: 0;
//   fill: ${({ theme }) => theme.colorAccent};
//   transform: scale(1.2);
//   pointer-events: none;
//   display: none;

//   input:checked + & {
//     display: block;
//   }
// `

// export const Checkbox = styled.input`
//   width: 20px;
//   height: 20px;
//   border: 1px solid ${({ theme }) => theme.colorWhite};
//   border-radius: 4px;
//   outline: none;
//   cursor: pointer;

//   &:checked:before {
//     display: none;
//   }
// `

export const Label = styled.label`
  font-family: ${({ theme }) => theme.fontfamilyPrimary};
  font-size: 0.72rem;
  padding-left: ${({ theme }) => theme.gutterSmall};
  cursor: pointer;
  color: red;

  a {
    color: red;
  }
`

// export const Message = styled(StyledMessage)`
//   position: static;
//   display: block;
//   text-align: right;
//   top: calc(100% + 0.3rem);
// `