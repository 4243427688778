import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import {
  Grid,
  TextItem,
  Title,
  TextContent,
  FAQItem,
  FAQGrid,
  FAQCard,
  FAQQuestion,
  FAQAnswer,
  Arrow
} from './styles'
import Button from '../Button'


const FAQShortcut = ({
  title,
  textContent,
  featuredQuestions
}) => {
  return (
    <Grid>
      <TextItem>
        <Title>{title}</Title>
        <TextContent
          className="rte"
          dangerouslySetInnerHTML={{ __html: textContent }}
        />
        <Button
          type="link"
          text="Frågor och svar"
          to="vanliga-fragor"
          icon="arrow-short"
        />
      </TextItem>
      <FAQItem>
        <FAQGrid>
          <FAQCard
            to={`/vanliga-fragor#${featuredQuestions[0].slug}`}
          >
            <FAQQuestion>{featuredQuestions[0].question}</FAQQuestion>
            <FAQAnswer
              dangerouslySetInnerHTML={{ __html: featuredQuestions[0].answer }}
            />
            <Arrow />
          </FAQCard>
          <FAQCard
            to={`/vanliga-fragor#${featuredQuestions[1].slug}`}
          >
            <FAQQuestion>{featuredQuestions[1].question}</FAQQuestion>
            <FAQAnswer
              dangerouslySetInnerHTML={{ __html: featuredQuestions[1].answer }}
            />
            <Arrow />
          </FAQCard>
        </FAQGrid>
      </FAQItem>
    </Grid>
  )
}

export default FAQShortcut

FAQShortcut.propTypes = {
  title: PropTypes.string.isRequired,
  textContent: PropTypes.string.isRequired,
  featuredQuestions: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
  })).isRequired
}

export const query = graphql`
  fragment FAQShortcutFragment on DatoCmsFaqRow {
    title
    textContent
    featuredQuestions {
      slug
      question
      answer
    }
  }
`