import React from 'react'
import PropTypes from 'prop-types'
import {
  Heading1, Heading2, Heading3
} from '../../layout/mixins'

const Heading = ({ className, size, children }) => {
  const Headings = {
    1: Heading1,
    2: Heading2,
    3: Heading3
  }
  const Heading = Headings[size] ? Headings[size] : Heading1
  return <Heading className={className}>{children}</Heading>
}

Heading.propTypes = {
  size: PropTypes.number,
  children: PropTypes.node.isRequired
}

Heading.defaultProps = {
  size: 1
}

export default Heading
