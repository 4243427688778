import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import {
  getClosest
} from '../../utils/helpers'
import Interweave from 'interweave'
import { ThemeProvider } from 'styled-components'
import scrollToElement from 'scroll-to-element'
import {
  themes, Section, Grid, Item, Content, ImageWrapper, Image, BackgroundWrapper, Background, BackgroundImgWrapper, ButtonAlign, Address, Pin, AddressText, StyledQuote, QuoteAlign, ArrowDown, ShortcutAlign, Shortcut,
} from './styles'
import Heading from '../Heading'
// eslint-disable-next-line import/no-named-as-default-member
import Button from '../Button'
import siteTheme from '../../layout/theme'


const TextWithImage = ({
  addAddress, address, addQuote, quote, addLink, link, linkText, text, title, image, asHero, alignRight, headingSize, addShortcut, children
}) => {
  const theme = asHero ? 'dark' : 'regular'

  const {
    phone,
    max
  } = siteTheme.breakpoints

  const fluidImage = {
    ...image?.fluid,
    sizes: `(max-width: ${phone}px) 100vw, (max-width: ${max}px) 50vw, 850px`
  }


  const ConditionalAddress = () => {
    if (addAddress && address) {
      return (
        <Address>
          <Pin />
          <AddressText dangerouslySetInnerHTML={{ __html: address }} />
        </Address>
      )
    }
    return null
  }

  const ConditionalLink = () => {
    if (addLink && link && linkText) {
      return (
        <ButtonAlign> 
          <Button
            type="link"
            to={link}
            theme={theme}
            text={linkText}
            icon="arrow-short"
          />
        </ButtonAlign>
      )
    }
    return null
  }

  const ConditionalQuote = () => {
    if (addQuote && quote) {
      return (
        <QuoteAlign>
          <StyledQuote text={quote} />
        </QuoteAlign>
      )
    }
    return null
  }

  const ConditionalShortcut = () => {
    if (addShortcut) {
      return (
        <ShortcutAlign>
          <Shortcut
            onClick={(e) => {
              const sectionAncestor = getClosest(e.target, '.section')
              if (sectionAncestor && document.getElementById('header')) {
                scrollToElement(sectionAncestor.nextSibling, {
                  offset: -document.getElementById('header').offsetHeight
                })
              }
            }}
            title="Hoppa till innehållet"
          >
            <hidden>Hoppa till innehållet</hidden>
            <ArrowDown />
          </Shortcut>
        </ShortcutAlign>
      )
    }
    return null
  }

  return (
    <>
      <ThemeProvider theme={themes[theme]}>
        <Grid
          className={`
            ${asHero && 'as-hero'}
          `}
          alignRight={alignRight}
        >
          <Item>
            <Content className="rte" asHero={asHero} alignRight={alignRight}>
              <Heading size={headingSize}>{title}</Heading>
              <Interweave content={text} />
              <ConditionalQuote />
              <ConditionalAddress />
              <ConditionalLink />
              <ConditionalShortcut />
              {children}
            </Content>
          </Item>
          { !asHero ? (
            <ImageWrapper alignRight={alignRight}>
              <Image fluid={fluidImage} />
            </ImageWrapper>
          ) : (
            <BackgroundImgWrapper>
              <Img fluid={fluidImage} />
            </BackgroundImgWrapper>
          ) }
        </Grid>
      </ThemeProvider>
    </>
  )
}

TextWithImage.propTypes = {
  addAddress: PropTypes.bool,
  addLink: PropTypes.bool,
  address: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  addQuote: PropTypes.bool,
  quote: PropTypes.string,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number.isRequired,
      base64: PropTypes.string.isRequired,
      sizes: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
      srcSet: PropTypes.string.isRequired,
    }).isRequired
  }).isRequired,
  alignRight: PropTypes.bool,
  headingSize: PropTypes.number,
  addShortcut: PropTypes.bool,
  asHero: PropTypes.bool
}

TextWithImage.defaultProps = {
  addLink: false,
  addAddress: false,
  addQuote: false,
  address: null,
  link: null,
  linkText: null,
  quote: null,
  alignRight: false,
  headingSize: 2,
  addShortcut: false,
  asHero: false
}

export default TextWithImage

export const query = graphql`
  fragment TextWithImageFragment on DatoCmsTextWithImage {
    addQuote
    quote
    addAddress
    addLink
    address
    link
    linkText
    text
    title
    alignRight
    image {
      fluid(maxWidth: 2048, sizes: "375, 750, 850, 1024, 1700, 2048") {
          ...GatsbyDatoCmsFluid
        }
    }
  }
`