import { isEmail } from './helpers'

const validateInputs = (values) => {
  let errors = false
  const errorMessages = {}

  Object.keys(values).forEach((key) => {

    if (values[key] === '') {
      // Check for emtpy fields
      errorMessages[key] = {
        type: 'error',
        text: 'Vänligen fyll i fältet'
      }
      errors = true
    } else if ((key === 'email') && !isEmail(values[key])) {
      // Check for incorrect email
      errorMessages[key] = {
        type: 'error',
        text: 'Vänligen ange en email adress i korrekt format.'
      }
      errors = true
    } else if ((key === 'consent') && !values[key]) {
      errorMessages[key] = {
        type: 'error',
        text: 'Vänligen godkänn användarvillkoren.'
      }
      errors = true
    } else {
      // Ortherwise set message to false
      errorMessages[key] = false
    }

  })

  if (!errors) return false
  return errorMessages
}

export default validateInputs