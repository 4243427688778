import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { isExternalUrl } from '../../utils/helpers'
import {
  Grid,
  InfoItem,
  LinkCard,
  TelephoneCard,
  InfoTitle,
  Subtitle,
  PhoneNumber,
  InfoText,
  ExternalLink,
  Link
} from './styles'

const InformationRow = ({ items }) => {

  const renderInfoItem = (item) => (
    <InfoItem>
      <LinkCard
        to={item.url}
        target={isExternalUrl(item.url) ? '_blank' : ''}
      >
        <InfoTitle>{item.title}</InfoTitle>
        <InfoText>{item.textContent}</InfoText>
        {isExternalUrl(item.url) && <ExternalLink />}
      </LinkCard>
    </InfoItem>
  )

  const renderTelephoneItem = (item) => {
    return (
      <InfoItem>
        <TelephoneCard>
          <InfoTitle>{item.title}</InfoTitle>
          <Subtitle>{item.subtitle}</Subtitle>
          <PhoneNumber href={`tel:${item.telephone}`}>{item.telephoneTitle ? item.telephoneTitle : item.telephone}</PhoneNumber>
          { item.additionalTelephone && (
            <PhoneNumber href={`tel:${item.additionalTelephone}`}>{item.additionalPhoneTitle ? item.additionalPhoneTitle : item.additionalTelephone}</PhoneNumber>
          )}
        </TelephoneCard>
      </InfoItem>
    )
  }

  const renderLinkTelephoneItem = (item) => {
    return (
      <InfoItem>
        <TelephoneCard>
          <InfoTitle>{item.title}</InfoTitle>
          <Subtitle>{item.textContent}</Subtitle>
          <Link 
            to={item.url}
            target={isExternalUrl(item.url) ? '_blank' : ''}
          >{item.linkTitle}</Link>
          <PhoneNumber href={`tel:${item.telephoneNumber}`}>{item.telephoneTitle ? item.telephoneTitle : item.telephoneNumber}</PhoneNumber>
        </TelephoneCard>
      </InfoItem>
    )
  }
  
  return (
    <Grid>
      {items.map((item) => (
        <>
          {item.type === 'DatoCmsExternalLink' && renderInfoItem(item)}
          {item.type === 'DatoCmsTelephoneContact' && renderTelephoneItem(item)}
          {item.type === 'DatoCmsExternalLinkPhone' && renderLinkTelephoneItem(item)}
        </>
      ))}
    </Grid>
  )
}

export default InformationRow

InformationRow.propTypes = {
  items: PropTypes.oneOf([
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      textContent: PropTypes.string
    }),
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      telephone: PropTypes.string.isRequired,
      telephoneTitle: PropTypes.string,
      additionalPhoneTitle: PropTypes.string,
      additionalTelephone: PropTypes.string,
    }),
    PropTypes.shape({
      telephoneNumber: PropTypes.string.isRequired,
      telephoneTitle: PropTypes.string.isRequired,
      telephone: PropTypes.string.isRequired,
      textContent: PropTypes.string,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      linkTitle: PropTypes.string.isRequired
    })
  ]).isRequired
}

export const query = graphql`
  fragment InformationTelephoneFragment on DatoCmsTelephoneContact {
    title
    telephone
    telephoneTitle
    additionalPhoneTitle
    additionalTelephone
    subtitle
  }
  fragment InformationLinkFragment on DatoCmsExternalLink {
    url
    title
    textContent
  }
  fragment InformationLinkPhoneFragment on DatoCmsExternalLinkPhone {
    telephoneNumber
    telephoneTitle
    textContent
    title
    url
    linkTitle
  }
`