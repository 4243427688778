import styled from 'styled-components'
import { breakpoint } from '../../layout/mixins'
import CloseIcon from '../../layout/icons/close.svg'

export const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zLevel4};
`

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colorBlack};
  z-index: ${({ theme }) => theme.zLevel3};
  opacity: 0.8;
`

export const Content = styled.div`
  z-index: ${({ theme }) => theme.zLevel4};
  width: 100%;
`

export const Close = styled(CloseIcon)`
  position: absolute;
  top: ${({ theme }) => theme.gutter};
  right: ${({ theme }) => theme.gutter};
  fill: ${({ theme }) => theme.colorWhite};
  width: 1.11rem;
  height: 1.11rem;
  z-index: ${({ theme }) => theme.zLevel4};
  cursor: pointer;

  ${breakpoint.up('desktop')`
    width: 2rem;
    height: 2rem;
  `}
`