import styled from 'styled-components'
import {
  Heading1,
  Heading3Styles,
  breakpoint
} from '../../layout/mixins'
import { trimUnits } from '../../utils/helpers'
import Icon from '../../components/Icon'
import Button from '../../components/Button'

export const Hero = styled.section`
 min-height: 20rem;
 background: url(/images/tile.svg);
 background-size: 50%;
 display: flex;
 align-items: center;
 justify-content: center;
`

export const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${breakpoint.up('desktop')`
    width: 30rem;
  `}
`

export const Title = styled(Heading1)`
  color: ${({ theme }) => theme.colorWhite};
  margin-bottom: ${({ theme }) => theme.gutterLarge};
`

export const Preamble = styled.span`
  color: ${({ theme }) => theme.colorWhite};
  margin-bottom: ${({ theme }) => theme.gutterLarge};
`

export const InputGroup = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.gutterLarge};
`

export const Form = styled.div`
  display: flex;
  padding-top: ${({ theme }) => theme.gutterLarge};
`

export const SearchButton = styled(Button)`
  width: 7rem;
  padding-left: ${({ theme }) => theme.gutterSmall};
  padding-right: ${({ theme }) => theme.gutterSmall};
  background: ${({ theme }) => theme.colorBlack};
  color: ${({ theme }) => theme.colorWhite};
`

export const Input = styled.input`
  width: 13.4rem;
  height: 3rem;
  border: 2px solid ${({ theme }) => theme.colorBlack};
  border-radius: ${({ theme }) => theme.buttonRadius};
  color: ${({ theme }) => theme.colorBlack};
  padding: 0 ${({ theme }) => theme.gutter};
  margin-right: ${({ theme }) => theme.gutter};

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colorBlack};
    padding-left: ${({ theme }) => theme.gutterLarge};
  }

  :-ms-input-placeholder {
     color: ${({ theme }) => theme.colorBlack};
  }

  &:focus::placeholder,
  &:focus::-webkit-input-placeholder {
    color: transparent;
  }

  &:focus:-ms-input-placeholder {
    color: transparent;
 }  
`

export const SearchErrorMessage = styled.div`
  position: absolute;
  top: calc(100% + 0.3rem);
  left: 0;
  font-size: 0.78rem;
  color: ${({ theme }) => theme.colorAccent};
`

export const SearchIcon = styled(Icon)`
  && {
    position: absolute;
    top: 50%;
    left: ${({ theme }) => theme.gutter};
    transform: translateY(-50%);
    width: 1.11rem;
    height: 1.11rem;
    fill: ${({ theme }) => theme.colorBlack};
  }

  ${Input}:focus + && {
    display: none;
  }

  ${({ inputEmpty }) => !inputEmpty && `
    display: none;
  `}      
`

export const Filter = styled.ul`
  display: flex;
  justify-content: center;
`
export const Category = styled.li`
  position: relative;
  padding: ${({ theme }) => `0 ${theme.gutterSmall}`};
  cursor: pointer;
  color: ${({ theme }) => theme.colorBlack};

  &:after {
    content: '/';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(60%, -50%);
    color: ${({ theme }) => theme.colorBlack};
  }

  &:last-of-type:after {
    display: none;
  }

  ${({ active, theme }) => active && `
    text-decoration: underline;
    color: ${theme.colorAccent};
  `}
`

export const Container = styled.div`
  position: relative;
  text-align: center;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colorBlack};

  &.margin-bottom {
    margin-bottom: ${({ theme }) => theme.gutterXLarge};
  }
`

export const LoadingOverlay = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colorWhite};
  z-index: ${({ theme }) => theme.zLevel1};
`

export const Controls = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ControlsTitle = styled.div`
  ${Heading3Styles}
  margin-bottom: ${({ theme }) => theme.gutterLarge};
`