import styled from 'styled-components'
import {
  Heading2
} from '../../layout/mixins'

export const Article = styled.article`
  margin-bottom: ${({ theme }) => theme.gutterLarge};
`

export const Widget = styled.div`
    max-width: 1200px;
    margin: 0 auto;

    #homeq-web-widget {
        color: #000 !important;
        font-family: ${({ theme }) => theme.fontfamilyPrimary} !important;
    }

    #homeq-web-widget .rc-slider {
        margin-top: 10px !important;
    }

    #homeq-web-widget .homeq.text-all-caps, #homeq-web-widget .homeq.text-semi-bold, #homeq-web-widget .homeq.text-small {
        font-family: ${({ theme }) => theme.fontfamilyPrimary} !important;
    }

    #homeq-web-widget .widget-label {
        color: #444;
    }

    #homeq-web-widget .widget-bg-color {
        background-color: #fff;
    }

    #homeq-web-widget .homeq-select {
        color: white !important;
    }

    #homeq-web-widget select {
        background-color: ${({ theme }) => theme.colorAccent};
        border-color: ${({ theme }) => theme.colorAccent} !important;
    }

    #homeq-web-widget .rc-slider-rail {
        background-color: ${({ theme }) => theme.colorAccent};
    }

    #homeq-web-widget .rc-slider-track {
        background-color: ${({ theme }) => theme.colorAccent};
    }

    #homeq-web-widget .rc-slider-handle {
        border: solid 2px ${({ theme }) => theme.colorAccent};
        background-color: ${({ theme }) => theme.colorAccent};
    }

    #homeq-web-widget .pagination-button {
        background-color: #ffffff;
        border: solid 1px #dddddd;
    }

    #homeq-web-widget .pagination-button-active {
        background-color: ${({ theme }) => theme.colorAccent};
        border: none;
    }

    #homeq-web-widget .pagination-button-text {
    
    }

    #homeq-web-widget .pagination-button-text-active {
        color: #ffffff;
    }

    #homeq-web-widget .pagination-dots-container {
        background-color: #ffffff;
        border: solid 1px #dddddd;
    }

    #homeq-web-widget .pagination-dots {
        color: #777777;
    }
`