import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import theme from '../../layout/theme'
import { getIcon } from '../../layout/getIcon'

const Icon = (props) => {

  const {
    name,
    color,
    coloringType,
    className
  } = props
  const [IconComponent, setIconComponent] = useState(null);

  useEffect(() => {
    // code to run on component mount

    // If icon is set to false return early
    if (!name) return

    // Import based on icon prop 
    getIcon(name)
      .then((module) => {
        const StyledIcon = styled(module.default)`

          ${coloringType === 'fill' && `fill: ${color};`}
          ${coloringType === 'stroke' && `stroke: ${color};`}

          width: ${({ theme }) => theme.iconSize};
          height: ${({ theme }) => theme.iconSize};
        `
        setIconComponent(StyledIcon)
      })
      .catch((error) => {
        console.log(error)
      }) 
  }, [])

  return IconComponent && <IconComponent className={className} />
}

Icon.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  color: PropTypes.string,
  coloringType: PropTypes.string
}

Icon.defaultProps = {
  name: '',
  color: theme.colorBlack,
  coloringType: 'fill'
}

export default Icon