import styled from 'styled-components'
import {
  breakpoint,
  Heading2,
  ParagraphStyles,
  Heading4Styles
} from '../../layout/mixins'
import CustomLink from '../CustomLink'
import Icon from '../Icon'

export const Grid = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint.up('tablet')`
    flex-direction: row;
  `}  
`

export const TextItem = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.gutterLarge};

  ${breakpoint.up('tablet')`
    padding-right: ${({ theme }) => theme.gutterXLarge};
    width: 50%;
  `}
`

export const IconsItem = styled.div`
  width: 100%;

  ${breakpoint.up('tablet')`
    width: 50%;
  `}
`

export const Title = styled(Heading2)`
  color: ${({ theme }) => theme.textcolorPrimary};

  ${breakpoint.up('desktop')`
    margin-bottom: ${({ theme }) => theme.gutterMedium};
  `}    
`

export const TextContent = styled.div`
  ${ParagraphStyles}
  color: ${({ theme }) => theme.textcolorPrimary};
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.gutter};

  ${breakpoint.up('tablet')`
    margin-bottom: 0;    
  `}
`

export const InfoList = styled.ul``

export const InfoItem = styled.li`
  margin-bottom: ${({ theme }) => theme.gutter};

  &:last-of-type {
    margin-bottom: 0;
  }
`

export const InfoGroup = styled.div`
  display: flex;
  align-items: center;  
`

export const InfoLink = styled(CustomLink)`
  display: inline-block;
`

export const InfoTitle = styled.span`
  ${Heading4Styles}
  margin-bottom: 0;
  margin-left: ${({ theme }) => theme.gutter};
  transition: color ${({ theme }) => theme.transitionFast};

  ${InfoLink}:hover & {
    color: ${({ theme }) => theme.colorLinkhover};
  }  
`

export const StyledIcon = styled(Icon)`
  && {
    transition: fill ${({ theme }) => theme.transitionFast};

    ${breakpoint.down('phone')`
      width: 2rem;
      height: 2rem;
    `}

    ${InfoLink}:hover & {
      fill: ${({ theme }) => theme.colorLinkhover};
    }    
  }
`