import styled, { css } from 'styled-components'
import {
  Heading4,
  Heading2
} from '../../layout/mixins'
import Icon from '../../components/Icon'

export const AreaInfo = styled.div`
  padding-top: ${({ theme }) => theme.gutterLarge};
`

export const ConnectionsTitle = styled(Heading4)``

export const ConnectionTime = styled(Heading4)`
  &:last-of-type {
    margin-bottom: ${({ theme }) => theme.gutterLarge};
  }
`

export const AddressesTitle = styled(Heading2)``

export const Addresses = styled.div``

export const TravelIcon = styled(Icon)`
  width: 3rem;
  height: 3rem;
  vertical-align: middle;
  margin-right: ${({ theme }) => theme.gutter};
`