/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { graphql } from 'gatsby'
import {
  NewsCta,
  Divider
} from './styles'
import Section from '../../components/Section/section'
import AreaListing from '../../components/AreaListing/areaListing'
import TextWithImage from '../../components/TextWithImage'
import RentInformation from '../../components/RentInformation/rentInformation'
import FAQShortcut from '../../components/FAQShortcut'
import NewsFeed from '../../components/NewsFeed/newsFeed'
import InformationRowImage from '../../components/InformationRowImage/informationRowImage'
import BookServices from '../../components/BookServices'
import FindResidence from '../../components/FindResidence/findResidence'
import Announcement from '../../components/Announcement/announcement'

const Residence = ({ data }) => {
  const {
    datoCmsAnnouncement: {
      announcement: [announcement]
    },
    datoCms: {
      hero: [hero],
      faq: [faq],
      findResidence: [findResidence],
      residenceListing: [residenceListing],
      informationRow,
      bookServices
    },
    areas: {
      edges: areas
    },
    rentInformation,
    allArticles: {
      edges: allArticles
    }
  } = data

  return (
    <>
      <Section
        horizontalPadding="max"
        verticalPadding="none"
        fullMobile
      >
        <TextWithImage
          {...hero}
          headingSize={1}
          addShortcut={!announcement.activateAnnouncement}
          asHero
          fullMobile
        />
      </Section>
      {announcement.activateAnnouncement && (
        <Section
          horizontalPadding="max"
          verticalPadding="none"
          fullMobile
        >
          <Announcement />
        </Section>
      )}

      { rentInformation && (
          <Section
          verticalPadding="top-bottom"
        >
          <RentInformation {...rentInformation} />
        </Section>
      )}

      <Section
        verticalPadding="none"
      >
        <Divider />
      </Section>

      <Section
        verticalPadding="top-bottom"
      >
        <FAQShortcut {...faq} />
      </Section>

      <Section
        verticalPadding="none"
      >
        <Divider />
      </Section>

      <Section
        verticalPadding="top"
      >
        <FindResidence {...findResidence} />
      </Section>

      <Section
        verticalPadding="top-bottom"
      >
        <InformationRowImage links={informationRow} />
      </Section>

      {bookServices && (
        <BookServices {...bookServices} />
      )}

      <Section verticalPadding="top">
        <AreaListing
          {...residenceListing}
          areas={areas.map((item) => {
            return {
              ...item.node,
              areaHero: item.node.areaHero[0],
              mapDetails: item.node.mapDetails[0]
            }
          })}
        />
      </Section>
      <Section>
        <NewsFeed articles={allArticles.map(({ node }) => node)} />
      </Section>

      <Section
        verticalPadding="bottom"
        alignContent="center"
      >
        <NewsCta
          type="link"
          text="Nyheter"
          to="/nyheter"
          icon="arrow-short"
        />
      </Section>
    </>
  )
}

export default Residence

export const query = graphql`
  query {
    datoCmsAnnouncement {
      announcement: notice {
        activateAnnouncement
      }
    }    
    datoCms: datoCmsResidence {
      hero {
        ...TextWithImageFragment
      }
      faq: faqRow {
        ...FAQShortcutFragment
      }
      findResidence {
        ...FindResidenceFragment
      }
      informationRow {
        type: __typename
        ...InformationRowImageFragment
      } 
      bookServices {
        ...BookServicesResidenceFragment
      }
      residenceListing {
        ...AreaListingFragment
      }
      seo: seoMetaTags {
        tags
      }
    }
    rentInformation: datoCmsRentInformationResidence {
      ...RentInformationResidenceFragment
    }    
    areas: allDatoCmsArea(sort: {fields: title, order: ASC}) {
      edges {
        node {
          title
          slug
          areaHero: hero {
            ...TextWithImageFragment
          }
          mapDetails {
            latitude
            longitude
          }
        }
      }
    }
    allArticles: allDatoCmsNews(limit: 4, sort: {fields: meta___createdAt, order: DESC}) {
      edges {
        node {
          title
          slug
          meta {
            createdAt
          }
          category {
            title
          }
          image {
            fluid(maxWidth: 470, sizes: "347, 694, 274, 548, 395, 478, 956") {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`