import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'

import {
  ButtonWrapper,
  LinkWrapper,
  ButtonInner,
  ButtonText,
  themes 
} from './styles'
import { getIcon } from '../../layout/getIcon'

const Button = (props) => {

  const {
    type,
    text,
    colorTheme,
    hoverEffect,
    icon,
    coloringType,
    className,
    children
  } = props
  const [IconComponent, setIconComponent] = useState(null);

  useEffect(() => {
    // code to run on component mount
    // If icon is set to false return early
    if (!icon) return

    // Import based on icon prop 
    try {
      getIcon(icon)
      .then((module) => {
        const StyledIcon = styled(module.default)`
  
          ${coloringType === 'fill' && `fill: ${themes[colorTheme].textColor};`}
          ${coloringType === 'stroke' && `stroke: ${themes[colorTheme].textColor};`}
  
          margin-left: ${({ theme }) => theme.buttonIconSize};
          width: ${({ theme }) => theme.buttonIconSize};
          height: ${({ theme }) => theme.buttonIconSize};
        `
        setIconComponent(StyledIcon)
      }) 
    } catch (error) {
      console.log("ER",error)
    }
  }, [])

  const Wrapper = type === 'button' ? ButtonWrapper : LinkWrapper

  return (
    <ThemeProvider theme={themes[colorTheme]}>
      <Wrapper
        {...props}
        className={className}
        theme={themes[colorTheme]}
        hoverEffect={hoverEffect}
      >
        <ButtonInner>
          {children}
          <ButtonText dangerouslySetInnerHTML={{ __html: text }} />
          {IconComponent && <IconComponent />}
        </ButtonInner>
      </Wrapper>
    </ThemeProvider>
  )
}

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'link']),
  text: PropTypes.string.isRequired,
  colorTheme: PropTypes.oneOf(['regular', 'accent', 'light', 'white']),
  hoverEffect: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  coloringType: PropTypes.string
}

Button.defaultProps = {
  type: 'button',
  text: 'I am a button',
  colorTheme: 'regular',
  hoverEffect: true,
  icon: '',
  coloringType: 'fill'
}

export default Button