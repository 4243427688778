import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Interweave from 'interweave'
import { ThemeProvider } from 'styled-components'
import scrollToElement from 'scroll-to-element'
import {
  themes,
  Section,
  Grid,
  Item,
  Content,
  ContentInner,
  StyledHeading,
  ImageWrapper,
  Image,
  BackgroundImgWrapper,
  // BackgroundWrapper,
  // Background,
  ButtonAlign,
  Address,
  Pin,
  AddressText,
  StyledQuote,
  QuoteAlign,
  ArrowDown,
  ShortcutAlign,
  Shortcut,
  Category,
  Date
} from './styles'
// eslint-disable-next-line import/no-named-as-default-member
import Button from '../Button'
import siteTheme from '../../layout/theme'


const SingleNewsHero = ({
  addAddress, address, addQuote, quote, addLink, link, linkText, text, title, image, asHero, alignRight, headingSize, addShortcut, category, date
}) => {
  const theme = asHero ? 'dark' : 'regular'
  const sectionRef = useRef()

  const {
    phone,
    max
  } = siteTheme.breakpoints

  const fluidImage = {
    ...image?.fluid,
    sizes: `(max-width: ${phone}px) 100vw, (max-width: ${max}px) 50vw, 850px`
  }


  const ConditionalAddress = () => {
    if (addAddress && address) {
      return (
        <Address>
          <Pin />
          <AddressText dangerouslySetInnerHTML={{ __html: address }} />
        </Address>
      )
    }
    return null
  }

  const ConditionalLink = () => {
    if (addLink && link && linkText) {
      return (
        <ButtonAlign>
          <Button
            type="link"
            to={link}
            theme={theme}
            text={linkText}
            icon="arrow-short"
          />
        </ButtonAlign>
      )
    }
    return null
  }

  const ConditionalQuote = () => {
    if (addQuote && quote) {
      return (
        <QuoteAlign>
          <StyledQuote text={quote} />
        </QuoteAlign>
      )
    }
    return null
  }

  const ConditionalShortcut = () => {
    if (addShortcut) {
      return (
        <ShortcutAlign>
          <Shortcut
            onClick={() => {
              if (sectionRef.current && document.getElementById('header')) {
                scrollToElement(sectionRef.current.nextSibling, {
                  offset: -document.getElementById('header').offsetHeight
                })
              }
            }}
            title="Hoppa till innehållet"
          >
            <hidden>Hoppa till innehållet</hidden>
            <ArrowDown />
          </Shortcut>
        </ShortcutAlign>
      )
    }
    return null
  }

  return (
    <>
      <ThemeProvider theme={themes[theme]}>
        <Section
          ref={sectionRef}
          asHero={asHero}
        >
          <Grid
            alignRight={alignRight}
            className={`
              ${asHero && 'as-hero'}
            `}
          >
            <Item>
              <Content className="rte" asHero={asHero} alignRight={alignRight}>
                <ContentInner>
                  <Category>{category}</Category>
                  <Date>{date}</Date>
                  <StyledHeading size={headingSize}>{title}</StyledHeading>
                  <Interweave content={text} />
                  <ConditionalQuote />
                  <ConditionalAddress />
                  <ConditionalLink />
                </ContentInner>
                <ConditionalShortcut />
              </Content>
            </Item>
            { !asHero ? (
              <ImageWrapper alignRight={alignRight}>
                <Image fluid={fluidImage} />
              </ImageWrapper>
            ) : (
              <BackgroundImgWrapper>
                <Img fluid={fluidImage} />
              </BackgroundImgWrapper>
            ) }
          </Grid>
        </Section>
      </ThemeProvider>
    </>
  )
}

SingleNewsHero.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number.isRequired,
      base64: PropTypes.string.isRequired,
      sizes: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
      srcSet: PropTypes.string.isRequired,
    }).isRequired
  }).isRequired,
  category: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  addAddress: PropTypes.bool,
  addLink: PropTypes.bool,
  address: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  addQuote: PropTypes.bool,
  quote: PropTypes.string,
  alignRight: PropTypes.bool,
  headingSize: PropTypes.number,
  addShortcut: PropTypes.bool,
  asHero: PropTypes.bool
}

SingleNewsHero.defaultProps = {
  addLink: false,
  addAddress: false,
  addQuote: false,
  address: null,
  link: null,
  linkText: null,
  quote: null,
  alignRight: false,
  headingSize: 2,
  addShortcut: false,
  asHero: false
}

export default SingleNewsHero