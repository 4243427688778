import styled from 'styled-components'

export const TestBlock = styled.div`
  background: ${({ theme }) => theme.colorGrey};
  margin-bottom: ${({ theme }) => theme.blockpadding};

  &.no-background {
    background: none;

    p, button {
      margin-bottom: ${({ theme }) => theme.gutter};
    }
  }

  &.button-group {
    background: none;
  }
`