import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { isExternalUrl } from '../../utils/helpers'
import {
  Wrapper,
  StyledImg,
  ImageWrapper,
  Inner,
  Content,
  Card,
  TextContent,
  Heading,
  ExternalLink
} from './styles'

const BookServices = ({ 
  title,
  text,
  url,
  backgroundImage
}) => {
  return (
    <Wrapper>
      <StyledImg fluid={backgroundImage.fluid} />
      <Inner>
        <Content>
          <Card
            to={url}
            target="_blank"
          >
            <Heading>{title}</Heading>
            <TextContent
              className="rte"
              dangerouslySetInnerHTML={{ __html: text }}
            />
            {isExternalUrl(url) && <ExternalLink />}
          </Card>
        </Content>
      </Inner>
    </Wrapper>
  )
}

export default BookServices

export const query = graphql`
  fragment BookServicesRealEstateFragment on DatoCmsBookServiceRealEstate {
    url
    title
    text
    backgroundImage {
      fluid(maxWidth: 2048) {
        ...GatsbyDatoCmsFluid
      }
    }
  }
  fragment BookServicesResidenceFragment on DatoCmsBookServiceResidence {
    url
    title
    text
    backgroundImage {
      fluid(maxWidth: 2048, sizes: "375, 750, 850, 1024, 1700, 2048") {
        ...GatsbyDatoCmsFluid
      }
    }
  }
`