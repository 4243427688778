import React from 'react'
import { graphql } from 'gatsby'
import {
  Article
} from './styles'
import TextWithImage from '../../components/TextWithImage'
import Section from '../../components/Section/section'

const SubPage = ({ data }) => {
  const {
    hero: [hero],
    textContent
  } = data.datoCmsSubpage

  return (
    <>
      <Section
        horizontalPadding="max"
        verticalPadding="none"
        fullMobile
      >
        <TextWithImage
          {...hero}
          headingSize={1}
          addShortcut
          asHero
        />
      </Section>
      <Section
        verticalPadding="top-bottom"
        horizontalPadding="article"
      >
        <Article
          className="rte"
          dangerouslySetInnerHTML={{ __html: textContent }}
        />
      </Section>
    </>
  )
}

export default SubPage

export const query = graphql`
  query SubPageQuery($slug: String!) {
    datoCmsSubpage(slug: {eq: $slug}) {
      slug
      hero {
        ...TextWithImageFragment
      }
      textContent      
      seoMetaTags {
        tags
      }
    }
  }
`
