import { graphql } from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'
import {
  Wrapper,
  Inner,
  Grid,
  TitleItem,
  TextItem,
  Title,
  TextContent,
  StyledButton
} from './styles'

const TwoColText = ({
  title,
  textContent,
  addLink,
  linkTitle,
  linkUrl
}) => {
  return (
    <Wrapper>
      <Inner>
        <Grid>
          <TitleItem>
            <Title>{title}</Title>
            {addLink && linkTitle && linkUrl && (
              <StyledButton
                type="link"
                text={linkTitle}
                icon="arrow-short"
                to={linkUrl}
              />
            )}
          </TitleItem>
          <TextItem>
            <TextContent
              className="rte"
              dangerouslySetInnerHTML={{ __html: textContent }}
            />
            {addLink && linkTitle && linkUrl && (
              <StyledButton
                type="link"
                text={linkTitle}
                icon="arrow-short"
                to={linkUrl}
              />
            )}
          </TextItem>
        </Grid>
      </Inner>
    </Wrapper>
  )
}

export default TwoColText

TwoColText.propTypes = {
  title: PropTypes.string.isRequired,
  textContent: PropTypes.string.isRequired,
  addLink: PropTypes.bool,
  linkTitle: PropTypes.string,
  linkUrl: PropTypes.string
}

TwoColText.defaultProps = {
  addLink: false,
  linkTitle: 'Link 1',
  linkUrl: 'https://sould.se'
}

export const TwoColTextQuery = graphql`
  fragment TwoColTextFragment on DatoCmsTextContent {
    title
    textContent
    addLink
    linkTitle
    linkUrl
  }
`