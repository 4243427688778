export const blockData = (blocks, blockName) => blocks
  .filter((block) => block['__typename'] === blockName)[0]

export const trimUnits = (value) => value.match(/(\d+(\.\d+)?)/)[0]

export const trimZeros = (value) => value.replace(/0/g, '')

export const isExternalUrl = (url) => {
  const pattern = /^((http|https|www):\/\/)/
  return pattern.test(url)
}

export const isHashUrl = (url) => {
  if (!url) return
  return url.includes('#')
}

export const isEmail = (email) => {
  const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return pattern.test(email)
}

export const getIframeSrc = (providerUid) =>
  // TODO: Adjust to enable other providers
  `https://www.youtube.com/embed/${providerUid}`

export const outsideClick = (targets, callback) => {
  const handler = (evt) => {
    const insideElements = Array.from(document.querySelectorAll(targets))
    let targetElement = evt.target // clicked element

    do {
      if (insideElements.includes(targetElement)) {
        // Click inside.
        // Do nothing, just return.
        return
      }
      // Go up the DOM.
      targetElement = targetElement.parentNode
    } while (targetElement)

    // Clicked outside. Do something useful here.
    if (typeof callback === 'function') {
      callback()
    }
  }

  const listen = () => {
    document.addEventListener('click', handler)
  }

  const remove = () => {
    document.removeEventListener('click', handler)
  }

  return {
    listen,
    remove
  }
}

export const formatDate = (isoString) => {
  const options = {
    month: 'numeric', day: 'numeric'
  }

  const testDate = new Date(isoString)
  const formatedDate = new Intl.DateTimeFormat('sv-SE', options).format(testDate)
  return trimZeros(formatedDate)
}

export const firstLetterUppercase = (string) => {
  return string
    .charAt(0)
    .toUpperCase() + string.slice(1).toLowerCase()
}

export const getClosest = (elem, selector) => {
  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.matchesSelector
    || Element.prototype.mozMatchesSelector
    || Element.prototype.msMatchesSelector
    || Element.prototype.oMatchesSelector
    || Element.prototype.webkitMatchesSelector
    || function (s) {
      const matches = (this.document || this.ownerDocument).querySelectorAll(s)
      let i = matches.length
      while (--i >= 0 && matches.item(i) !== this) {}
      return i > -1
    }
  }

  // Get the closest matching element
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem
  }
  return null
}

export const removeDuplicates = (array, key = 'id') => {
  const lookup = new Set()
  return array.filter((obj) => !lookup.has(obj[key]) && lookup.add(obj[key]))
}
