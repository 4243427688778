import styled from 'styled-components'
import { breakpoint } from '../../layout/mixins'
import { Halign } from '../../layout/mixins'
import SpeachBubble from '../SpeachBubble'
import ServiceNotification from '../ServiceNotification/serviceNotification'

export const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: ${({ theme }) => theme.gutterXXLarge};
  width: 100%;
  height: 100%;
  padding-top: ${({ theme: { headerheightMobile, gutter } }) => `calc(${headerheightMobile} + ${gutter})`};
  background: ${({ theme }) => theme.colorBlack};
  z-index: ${({ theme }) => theme.zLevel2};
  pointer-events: none;
  transition: ${({ theme }) => `all ${theme.transitionFast}`};
  opacity: 0;

  .menu-open & {
    opacity: 1;
    top: 0;
    left: 0;
    pointer-events: auto;
  }

  ${breakpoint.up('desktop')`
    .menu-open & {
      display: none;
    }
  `}
`

export const StyledHalign = styled(Halign)`
    height: 100%;
`

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.gutter};
`

export const NavList = styled.ul`
  margin-bottom: ${({ theme }) => theme.gutterMedium};
`

export const NavItem = styled.li`
  font-family: ${({ theme }) => theme.fontfamilyPrimary};
  font-size: 24px;
  line-height: 1.208em;
  margin-bottom: ${({ theme }) => theme.gutter};
  cursor: pointer;

  a.active-link {
    font-weight: 700;
  }

  && a {
    color: ${({ theme }) => theme.textcolorSecondary};
  }
`

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 10rem;

  a {
    margin-bottom: ${({ theme }) => theme.gutterMedium};
    text-align: center;
  }
`

export const StyledSpeachBubble = styled(SpeachBubble)`
  margin-bottom: ${({ theme }) => theme.gutterLarge};
`

export const StyledServiceNotification = styled(ServiceNotification)`
  position: static;
  display: block;
`